var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-spin",
    { style: _vm.backCss, attrs: { spinning: _vm.skipLoading } },
    [
      _c(
        "body-layout",
        { attrs: { title: "" } },
        [
          _vm.isDesktop()
            ? _c(
                "div",
                {
                  staticClass: "actions flex",
                  staticStyle: { "flex-wrap": "wrap", position: "relative" },
                  attrs: { slot: "actions" },
                  slot: "actions",
                },
                [
                  false
                    ? _c(
                        "a-button",
                        {
                          staticClass: "mr-3",
                          attrs: { type: "danger" },
                          on: { click: _vm.batchDel },
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e(),
                  _c(
                    "a-button-group",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "a-tooltip",
                        [
                          _c("template", { slot: "title" }, [_vm._v(" 列表 ")]),
                          _c("a-button", {
                            style:
                              _vm.viewType == "list"
                                ? "width:45px;z-index:9"
                                : "width:45px",
                            attrs: {
                              icon: "unordered-list",
                              type: _vm.viewType == "list" ? "primary" : "",
                              ghost: _vm.viewType == "list" ? true : false,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changeViewType("list")
                              },
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "a-tooltip",
                        [
                          _c("template", { slot: "title" }, [_vm._v(" 日历 ")]),
                          _c("a-button", {
                            style:
                              _vm.viewType == "calendar" && !_vm.isView
                                ? "width:45px;z-index:9"
                                : "width:45px",
                            attrs: {
                              icon: "table",
                              type:
                                _vm.viewType == "calendar" && !_vm.isView
                                  ? "primary"
                                  : "",
                              ghost:
                                _vm.viewType == "calendar" && !_vm.isView
                                  ? true
                                  : false,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changeViewType("calendar")
                              },
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "a-tooltip",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(" 备忘录 "),
                          ]),
                          _c("a-button", {
                            style:
                              _vm.viewType == "calendar" && _vm.isView
                                ? "width:45px;z-index:9"
                                : "width:45px",
                            attrs: {
                              icon: "calendar",
                              type:
                                _vm.viewType == "calendar" && _vm.isView
                                  ? "primary"
                                  : "",
                              ghost:
                                _vm.viewType == "calendar" && _vm.isView
                                  ? true
                                  : false,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changeViewType("calendar-table")
                              },
                            },
                          }),
                        ],
                        2
                      ),
                      _vm.userInfo.positions &&
                      _vm.userInfo.positions.some(
                        (p) =>
                          p.name == "翻译" ||
                          p.name == "校对" ||
                          p.name == "终审"
                      )
                        ? _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" 待确认 "),
                              ]),
                              _c(
                                "a-badge",
                                { attrs: { count: _vm.confirmTableTotal } },
                                [
                                  _c("a-button", {
                                    style:
                                      _vm.viewType == "confirm-list"
                                        ? "width:45px;z-index:9"
                                        : "width:45px",
                                    attrs: {
                                      icon: "audit",
                                      type:
                                        _vm.viewType == "confirm-list"
                                          ? "primary"
                                          : "",
                                      ghost:
                                        _vm.viewType == "confirm-list"
                                          ? true
                                          : false,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeViewType(
                                          "confirm-list"
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      style: `position: absolute; bottom: 0; ${
                        _vm.userInfo.positions &&
                        _vm.userInfo.positions.some(
                          (p) =>
                            p.name == "翻译" ||
                            p.name == "校对" ||
                            p.name == "终审"
                        )
                          ? "right: 200px;"
                          : "right: 165px;"
                      }height:32px; width: 230px; display: flex; justify-content: end`,
                    },
                    [
                      (_vm.viewType == "calendar" && !_vm.isView) ||
                      _vm.viewType == "list"
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-right": "15px",
                                "line-height": "32px",
                                position: "absolute",
                                top: "1px",
                              },
                              style: {
                                left:
                                  _vm.viewType == "list"
                                    ? !_vm.selectionRows.some(
                                        (item) => item.taskName != "给稿"
                                      ) ||
                                      !_vm.selectionRows.some(
                                        (item) => item.taskName != "制作"
                                      ) ||
                                      _vm.selectionRows.every(
                                        (item) =>
                                          item.taskName == "监制确认" ||
                                          item.taskName == "监制"
                                      )
                                      ? "-40px"
                                      : "0px"
                                    : !_vm.selectionRows.some(
                                        (item) => item.taskName != "给稿"
                                      ) ||
                                      !_vm.selectionRows.some(
                                        (item) => item.taskName != "制作"
                                      ) ||
                                      _vm.selectionRows.every(
                                        (item) =>
                                          item.taskName == "监制确认" ||
                                          item.taskName == "监制"
                                      )
                                    ? "0px"
                                    : "43px",
                              },
                            },
                            [
                              _c(
                                "a-tooltip",
                                {
                                  attrs: {
                                    placement: "bottom",
                                    transitionName: "",
                                  },
                                },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          padding: "6px 12px",
                                          "font-size": "12px",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              height: "32px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  height: "28px",
                                                  "line-height": "28px",
                                                  padding: "0 4px",
                                                  background:
                                                    "rgb(255 229 199)",
                                                  color: "orange",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    任务名称\n                  "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  height: "28px",
                                                  "line-height": "28px",
                                                  "margin-left": "5px",
                                                },
                                              },
                                              [_vm._v("驳回给他人的任务")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              height: "32px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  height: "28px",
                                                  "line-height": "28px",
                                                  padding: "0 4px",
                                                  background: "#fff9b7",
                                                  color: "orange",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    任务名称\n                  "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  height: "28px",
                                                  "line-height": "28px",
                                                  "margin-left": "5px",
                                                },
                                              },
                                              [_vm._v("拖稿的任务")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              height: "32px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  height: "28px",
                                                  "line-height": "28px",
                                                  padding: "0 3px",
                                                  "background-color":
                                                    "rgb(255 225 225)",
                                                  border: "1px solid #ffa39e",
                                                  color: "orange",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    任务名称\n                  "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "5px",
                                                  position: "relative",
                                                  top: "-4px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    高优先级（需要在2小时内完成）"
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  "或截止日期在当日18点之前被驳回的任务\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              height: "32px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  height: "28px",
                                                  "line-height": "28px",
                                                  padding: "0 4px",
                                                  "background-color": "#f8f8f8",
                                                  color: "orange",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    任务名称\n                  "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  height: "28px",
                                                  "line-height": "28px",
                                                  "margin-left": "5px",
                                                },
                                              },
                                              [_vm._v("被驳回的任务")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              height: "32px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  height: "28px",
                                                  "line-height": "28px",
                                                  padding: "0 4px",
                                                  "background-color": "#fff9b7",
                                                  color: "green",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    任务名称\n                  "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  height: "28px",
                                                  "line-height": "28px",
                                                  "margin-left": "5px",
                                                },
                                              },
                                              [_vm._v("拖稿的已完成的任务")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              height: "32px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  height: "28px",
                                                  "line-height": "28px",
                                                  padding: "0 4px",
                                                  "background-color": "#f8f8f8",
                                                  color: "green",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    任务名称\n                  "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  height: "28px",
                                                  "line-height": "28px",
                                                  "margin-left": "5px",
                                                },
                                              },
                                              [_vm._v("常规已完成的任务")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              height: "32px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  height: "28px",
                                                  "line-height": "28px",
                                                  padding: "0 4px",
                                                  "background-color":
                                                    "rgb(255 229 199)",
                                                  color: "red",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    任务名称\n                  "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  height: "28px",
                                                  "line-height": "28px",
                                                  "margin-left": "5px",
                                                },
                                              },
                                              [_vm._v("驳回过的可开始的任务")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              height: "32px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  height: "28px",
                                                  "line-height": "28px",
                                                  padding: "0 4px",
                                                  "background-color": "#f8f8f8",
                                                  color: "red",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    任务名称\n                  "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  height: "28px",
                                                  "line-height": "28px",
                                                  "margin-left": "5px",
                                                },
                                              },
                                              [_vm._v("常规可开始的任务")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              height: "32px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  height: "28px",
                                                  "line-height": "28px",
                                                  padding: "0 3px",
                                                  border: "1px solid orange",
                                                },
                                              },
                                              [_vm._v("任务名称")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  height: "28px",
                                                  "line-height": "28px",
                                                  "margin-left": "5px",
                                                },
                                              },
                                              [_vm._v("被驳回过的任务")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "margin-top": "10px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "5px",
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "circular",
                                                  staticStyle: {
                                                    background: "green",
                                                    "margin-left": "3px",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "3px",
                                                    },
                                                  },
                                                  [_vm._v("已完成")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "5px",
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "circular",
                                                  staticStyle: {
                                                    background: "red",
                                                    "margin-left": "3px",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "3px",
                                                    },
                                                  },
                                                  [_vm._v("可开始")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "5px",
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "circular",
                                                  staticStyle: {
                                                    background: "gray",
                                                    "margin-left": "3px",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "3px",
                                                    },
                                                  },
                                                  [_vm._v("等待中")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "5px",
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "circular",
                                                  staticStyle: {
                                                    background: "orange",
                                                    "margin-left": "3px",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "3px",
                                                    },
                                                  },
                                                  [_vm._v("驳回")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("a-icon", {
                                    staticStyle: { "font-size": "16px" },
                                    attrs: { type: "question-circle" },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isView &&
                      _vm.viewType != "list" &&
                      _vm.viewType != "confirm-list"
                        ? _c(
                            "a-dropdown",
                            { attrs: { placement: "bottomCenter" } },
                            [
                              _c("a-button", {
                                staticStyle: {
                                  "margin-left": "10px",
                                  width: "45px",
                                  "border-right": "none",
                                  "border-top-right-radius": "unset",
                                  "border-bottom-right-radius": "unset",
                                },
                                attrs: { icon: "check-square" },
                              }),
                              _c(
                                "a-menu",
                                { attrs: { slot: "overlay" }, slot: "overlay" },
                                [
                                  _vm.viewType == "calendar"
                                    ? _c(
                                        "a-menu-item",
                                        {
                                          directives: [
                                            {
                                              name: "has",
                                              rawName: "v-has",
                                              value: "myTask:batchDrop",
                                              expression: "'myTask:batchDrop'",
                                            },
                                          ],
                                          attrs: {
                                            disabled: _vm.isCalendarCancel,
                                          },
                                          on: { click: _vm.changeDropSelect },
                                        },
                                        [_vm._v("批量拖拽")]
                                      )
                                    : _vm._e(),
                                  _vm.viewType == "calendar"
                                    ? _c(
                                        "a-menu-item",
                                        {
                                          attrs: {
                                            disabled: _vm.isCalendarDropCancel,
                                          },
                                          on: { click: _vm.changeSelect },
                                        },
                                        [_vm._v("批量完成/下载")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      (_vm.viewType != "calendar" || !_vm.isView) &&
                      _vm.viewType != "confirm-list"
                        ? _c(
                            "a-dropdown",
                            {
                              attrs: {
                                disabled:
                                  !_vm.selectionRows.length ||
                                  _vm.selectionRows.some(
                                    (item) =>
                                      item.taskName !=
                                      _vm.selectionRows[0].taskName
                                  ) ||
                                  !(
                                    ((_vm.viewType == "calendar" &&
                                      !_vm.isView) ||
                                      _vm.viewType == "list") &&
                                    (!_vm.selectionRows.some(
                                      (item) => item.taskName != "给稿"
                                    ) ||
                                      _vm.selectionRows.every(
                                        (item) =>
                                          item.taskName == "制作" &&
                                          item.systemTypes &&
                                          item.systemTypes.some(
                                            (item) =>
                                              item == "IMAGE_TRANSLATION_SYSTEM"
                                          )
                                      ) ||
                                      _vm.selectionRows.every(
                                        (item) =>
                                          (item.taskName == "监制确认" ||
                                            item.taskName == "监制" ||
                                            item.taskName == "交稿") &&
                                          item.applyReview
                                      ) ||
                                      _vm.selectionRows.every(
                                        (item) =>
                                          (item.taskName == "制作" ||
                                            item.taskName == "制作修改" ||
                                            item.stageName == "监制" ||
                                            item.stageName == "监制确认" ||
                                            item.stageName.indexOf("建模") >
                                              -1 ||
                                            item.stageName.indexOf("精草") >
                                              -1 ||
                                            item.stageName.indexOf("勾线") >
                                              -1 ||
                                            item.stageName.indexOf("上色") >
                                              -1 ||
                                            item.stageName.indexOf("后期") >
                                              -1) &&
                                          item.applyReview
                                      ))
                                  ),
                                placement: "bottomCenter",
                              },
                            },
                            [
                              _c("a-button", {
                                style: {
                                  width: "45px",
                                  borderRight: "none",
                                  borderTopRightRadius: "unset",
                                  borderBottomRightRadius: "unset",
                                  borderTopLeftRadius:
                                    _vm.viewType == "list" ? "" : "unset",
                                  borderBottomLeftRadius:
                                    _vm.viewType == "list" ? "" : "unset",
                                },
                                attrs: { icon: "download" },
                              }),
                              _c(
                                "a-menu",
                                { attrs: { slot: "overlay" }, slot: "overlay" },
                                [
                                  !_vm.selectionRows.some(
                                    (item) => item.taskName != "给稿"
                                  )
                                    ? _c(
                                        "a-menu-item",
                                        {
                                          directives: [
                                            {
                                              name: "has",
                                              rawName: "v-has",
                                              value: "myTasks::button:admin",
                                              expression:
                                                "'myTasks::button:admin'",
                                            },
                                          ],
                                          on: { click: _vm.openManuscriptList },
                                        },
                                        [_vm._v("上传原稿")]
                                      )
                                    : _vm._e(),
                                  _vm.selectionRows.every(
                                    (item) =>
                                      (item.taskName == "制作" ||
                                        item.taskName == "制作修改" ||
                                        item.stageName == "监制" ||
                                        item.stageName == "监制确认" ||
                                        item.stageName.indexOf("建模") > -1 ||
                                        item.stageName.indexOf("精草") > -1 ||
                                        item.stageName.indexOf("勾线") > -1 ||
                                        item.stageName.indexOf("上色") > -1 ||
                                        item.stageName.indexOf("后期") > -1) &&
                                      item.applyReview &&
                                      ((item.taskStatus != "等待" &&
                                        item.taskStatus != "驳回" &&
                                        item.taskStatus != "已完成") ||
                                        (item.taskStatus == "驳回" &&
                                          item.rejectTaskId))
                                  )
                                    ? _c(
                                        "a-menu-item",
                                        {
                                          directives: [
                                            {
                                              name: "has",
                                              rawName: "v-has",
                                              value: "myTasks::button:admin",
                                              expression:
                                                "'myTasks::button:admin'",
                                            },
                                          ],
                                          on: { click: _vm.openSumbmitList },
                                        },
                                        [_vm._v("上传完成稿")]
                                      )
                                    : _vm._e(),
                                  _vm.selectionRows.every(
                                    (item) =>
                                      item.taskName == "制作" &&
                                      item.systemTypes &&
                                      item.systemTypes.some(
                                        (item) =>
                                          item == "IMAGE_TRANSLATION_SYSTEM"
                                      )
                                  )
                                    ? _c(
                                        "a-menu-item",
                                        {
                                          directives: [
                                            {
                                              name: "has",
                                              rawName: "v-has",
                                              value: "myTasks::button:admin",
                                              expression:
                                                "'myTasks::button:admin'",
                                            },
                                          ],
                                          on: { click: _vm.downloadMake },
                                        },
                                        [_vm._v("下载制作稿")]
                                      )
                                    : _vm._e(),
                                  _vm.selectionRows.every(
                                    (item) =>
                                      (item.taskName == "监制确认" ||
                                        item.stageName == "勾线终审" ||
                                        item.stageName == "上色终审" ||
                                        item.taskName == "监制" ||
                                        item.taskName == "交稿") &&
                                      item.applyReview
                                  )
                                    ? _c(
                                        "a-menu-item",
                                        {
                                          directives: [
                                            {
                                              name: "has",
                                              rawName: "v-has",
                                              value: "myTasks::button:admin",
                                              expression:
                                                "'myTasks::button:admin'",
                                            },
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.downloadOver(false)
                                            },
                                          },
                                        },
                                        [_vm._v("下载终稿")]
                                      )
                                    : _vm._e(),
                                  _vm.selectionRows.every(
                                    (item) =>
                                      (item.taskName == "监制确认" ||
                                        item.taskName == "监制") &&
                                      item.applyReview &&
                                      item.departName.indexOf("日语部") > -1
                                  )
                                    ? _c(
                                        "a-menu-item",
                                        {
                                          directives: [
                                            {
                                              name: "has",
                                              rawName: "v-has",
                                              value: "myTasks::button:admin",
                                              expression:
                                                "'myTasks::button:admin'",
                                            },
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.downloadOver(true)
                                            },
                                          },
                                        },
                                        [_vm._v("下载切片")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.viewType == "list"
                        ? _c(
                            "a-button",
                            {
                              staticStyle: {
                                width: "45px",
                                "border-right": "none",
                                "border-radius": "unset",
                              },
                              on: {
                                click: _vm.sortTable,
                                mouseover: function ($event) {
                                  _vm.color1 = _vm.color
                                },
                                mouseout: function ($event) {
                                  _vm.color1 = "#4a4a4a"
                                },
                              },
                            },
                            [
                              _c("sort-two", {
                                staticStyle: {
                                  position: "relative",
                                  top: "2px",
                                },
                                attrs: {
                                  theme: "outline",
                                  size: "18",
                                  fill: _vm.color1,
                                  strokeWidth: 3,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.viewType == "list"
                        ? _c("a-button", {
                            staticStyle: {
                              width: "45px",
                              "border-right": "none",
                              "border-radius": "unset",
                            },
                            attrs: {
                              icon: "edit",
                              disabled:
                                _vm.isCalendarDropCancel ||
                                !this.selectionRows.length,
                            },
                            on: { click: _vm.openMonthList },
                          })
                        : _vm._e(),
                      _vm.viewType == "calendar" && !_vm.isView
                        ? _c(
                            "a-dropdown",
                            {
                              attrs: {
                                visible: _vm.visible,
                                disabled:
                                  _vm.selectKeys.length == 0 ||
                                  (_vm.selectKeys.length &&
                                    (_vm.selectKeys.some((item, index) => {
                                      return (
                                        index &&
                                        item.chapterId ==
                                          _vm.selectKeys[0].chapterId
                                      )
                                    }) ||
                                      _vm.selectKeys.some((item, index) => {
                                        return (
                                          !item.rejectTaskId &&
                                          item.taskStatus === "驳回"
                                        )
                                      }) ||
                                      _vm.selectKeys.some((item) => {
                                        return item.taskStatus === "等待"
                                      }))),
                                trigger: ["click"],
                              },
                              on: { visibleChange: _vm.visibleChange },
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  staticStyle: {
                                    "border-top-left-radius": "unset",
                                    "border-bottom-left-radius": "unset",
                                    "margin-right": "12px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.getRejectStages.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("完成状态")]
                              ),
                              _vm.selectedRowKeys.length ||
                              _vm.selectKeys.length
                                ? _c(
                                    "a-menu",
                                    {
                                      attrs: { slot: "overlay" },
                                      slot: "overlay",
                                    },
                                    [
                                      _vm.selectKeys.every(
                                        (item) => item.taskStatus !== "已完成"
                                      ) &&
                                      _vm.selectKeys.every(
                                        (item) => item.stageName != "甲方反馈"
                                      )
                                        ? _c(
                                            "a-menu-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleTaskFine(
                                                    false
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("div", [
                                                _c("span", {
                                                  staticClass: "circular",
                                                  staticStyle: {
                                                    background: "green",
                                                    "margin-left": "3px",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "5px",
                                                    },
                                                  },
                                                  [_vm._v("已完成")]
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.selectKeys.every(
                                        (item) => item.taskStatus == "已完成"
                                      ) &&
                                      (_vm.isTongChou || _vm.isAdmin)
                                        ? _c(
                                            "a-menu-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleTaskStart(
                                                    "calendar"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("div", [
                                                _c("span", {
                                                  staticClass: "circular",
                                                  staticStyle: {
                                                    background: "red",
                                                    "margin-left": "3px",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "5px",
                                                    },
                                                  },
                                                  [_vm._v("可开始")]
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.selectKeys.every(
                                        (item) => item.taskStatus !== "已完成"
                                      ) &&
                                      _vm.selectKeys.every(
                                        (item) => item.stageName == "甲方反馈"
                                      )
                                        ? _c(
                                            "a-menu-item",
                                            {
                                              staticClass: "status-item",
                                              attrs: {
                                                disabled: _vm.selectKeys.some(
                                                  (item) => item.hasFeedback
                                                ),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleTaskFine(
                                                    false
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "texe-align": "center",
                                                    "padding-left": "10px",
                                                  },
                                                },
                                                [_vm._v("无反馈")]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.selectKeys.every(
                                        (item) => item.taskStatus !== "已完成"
                                      ) &&
                                      _vm.selectKeys.every(
                                        (item) => item.stageName == "甲方反馈"
                                      ) &&
                                      !_vm.rejectShow
                                        ? _c(
                                            "a-menu-item",
                                            {
                                              staticClass: "status-item",
                                              on: {
                                                click: () =>
                                                  (this.rejectShow = true),
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "texe-align": "center",
                                                    "padding-left": "10px",
                                                  },
                                                },
                                                [_vm._v("有反馈")]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.selectKeys.every(
                                        (item) => item.taskStatus !== "已完成"
                                      ) &&
                                      _vm.selectKeys.every(
                                        (item) => item.stageName == "甲方反馈"
                                      ) &&
                                      _vm.rejectShow
                                        ? _c(
                                            "a-menu-item",
                                            {
                                              staticClass: "status-item",
                                              on: {
                                                click: () =>
                                                  _vm.handleTaskFine(true),
                                              },
                                            },
                                            [
                                              _c("div", {
                                                staticClass: "circular",
                                                staticStyle: {
                                                  background: "green",
                                                  "margin-left": "3px",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "5px",
                                                  },
                                                },
                                                [_vm._v("已完成")]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.selectKeys.every(
                                        (item) => item.taskStatus !== "已完成"
                                      ) &&
                                      _vm.selectKeys.every(
                                        (item) => item.taskStatus !== "驳回"
                                      ) &&
                                      _vm.selectKeys.every(
                                        (item) => item.stageName !== "给稿"
                                      ) &&
                                      _vm.rejectStages.length > 0 &&
                                      _vm.rejectShow
                                        ? _vm._l(
                                            _vm.rejectStages,
                                            function (item, idx) {
                                              return _vm.rejectStages.length > 1
                                                ? _c(
                                                    "a-menu-item",
                                                    { key: idx },
                                                    [
                                                      _c(
                                                        "a-checkbox",
                                                        {
                                                          on: {
                                                            change: (e) => {
                                                              _vm.onChange(
                                                                e,
                                                                item
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value: item.checked,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "checked",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.checked",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a-dropdown",
                                                            {
                                                              attrs: {
                                                                trigger: [
                                                                  "hover",
                                                                ],
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "line-height":
                                                                      "30px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "驳回-" +
                                                                      _vm._s(
                                                                        item.stageName
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    position:
                                                                      "absolute",
                                                                    top: "-4vh",
                                                                    left: "4.5vw",
                                                                    "box-shadow":
                                                                      "0 2px 8px rgb(0 0 0 / 15%)",
                                                                  },
                                                                  attrs: {
                                                                    slot: "overlay",
                                                                  },
                                                                  slot: "overlay",
                                                                },
                                                                [
                                                                  _c(
                                                                    "a-menu",
                                                                    [
                                                                      _c(
                                                                        "a-menu-item",
                                                                        {
                                                                          key: "HIGH",
                                                                          staticStyle:
                                                                            {
                                                                              height:
                                                                                "30px",
                                                                              "line-height":
                                                                                "30px",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.priorityChange(
                                                                                  item,
                                                                                  "高"
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "高\n                        "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "a-menu-item",
                                                                        {
                                                                          key: "MIDDLE",
                                                                          staticStyle:
                                                                            {
                                                                              height:
                                                                                "30px",
                                                                              "line-height":
                                                                                "30px",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.priorityChange(
                                                                                  item,
                                                                                  "普通"
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "普通\n                        "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "a-menu-item",
                                                                        {
                                                                          key: "LOW",
                                                                          staticStyle:
                                                                            {
                                                                              height:
                                                                                "30px",
                                                                              "line-height":
                                                                                "30px",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.priorityChange(
                                                                                  item,
                                                                                  "低"
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "低\n                        "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._l(
                                                    _vm.rejectStages,
                                                    function (item, idx) {
                                                      return _c(
                                                        "a-sub-menu",
                                                        {
                                                          key: "submenu" + idx,
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                display: "flex",
                                                                "align-items":
                                                                  "center",
                                                                "line-height":
                                                                  "30px",
                                                                "min-width":
                                                                  "100%",
                                                              },
                                                              attrs: {
                                                                slot: "title",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.priorityChange(
                                                                      item,
                                                                      "普通"
                                                                    )
                                                                  },
                                                              },
                                                              slot: "title",
                                                            },
                                                            [
                                                              _c("div", {
                                                                staticClass:
                                                                  "statusPointer",
                                                                style: {
                                                                  background:
                                                                    "orange",
                                                                },
                                                              }),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "2px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " 驳回-" +
                                                                      _vm._s(
                                                                        item.stageName
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "a-menu-item",
                                                            {
                                                              key: "HIGH",
                                                              staticStyle: {
                                                                "line-height":
                                                                  "30px",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.priorityChange(
                                                                      item,
                                                                      "高"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "高\n                "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "a-menu-item",
                                                            {
                                                              key: "MIDDLE",
                                                              staticStyle: {
                                                                "line-height":
                                                                  "30px",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.priorityChange(
                                                                      item,
                                                                      "普通"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "普通\n                "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "a-menu-item",
                                                            {
                                                              key: "LOW",
                                                              staticStyle: {
                                                                "line-height":
                                                                  "30px",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.priorityChange(
                                                                      item,
                                                                      "低"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("低 ")]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  )
                                            }
                                          )
                                        : _vm._e(),
                                      _vm.rejectStages.length > 1 &&
                                      _vm.selectKeys.every(
                                        (item) => item.taskStatus !== "已完成"
                                      ) &&
                                      _vm.selectKeys.every(
                                        (item) => item.taskStatus !== "驳回"
                                      ) &&
                                      _vm.rejectShow
                                        ? _c(
                                            "a-menu-item",
                                            {
                                              staticStyle: {
                                                "background-color":
                                                  "#fff !important",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "task-btn" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "50%",
                                                      },
                                                      on: {
                                                        click: () => {
                                                          this.visible =
                                                            !this.visible
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  取消\n                "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      style: `color:${_vm.color};width:50%`,
                                                      on: {
                                                        click: _vm.submitTask,
                                                      },
                                                    },
                                                    [_vm._v("确定")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.viewType == "list"
                        ? _c(
                            "a-dropdown",
                            {
                              attrs: {
                                visible: _vm.visible,
                                disabled:
                                  _vm.selectionRows.length == 0 ||
                                  (_vm.selectionRows.length &&
                                    (_vm.selectionRows.some((item, index) => {
                                      return (
                                        index &&
                                        item.chapterId ==
                                          _vm.selectionRows[0].chapterId
                                      )
                                    }) ||
                                      _vm.selectionRows.some((item, index) => {
                                        return (
                                          !item.rejectTaskId &&
                                          item.taskStatus === "驳回"
                                        )
                                      }) ||
                                      _vm.selectionRows.some((item) => {
                                        return item.taskStatus === "等待"
                                      }))),
                                trigger: ["click"],
                              },
                              on: { visibleChange: _vm.visibleChange },
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  staticStyle: {
                                    "border-top-left-radius": "unset",
                                    "border-bottom-left-radius": "unset",
                                    "margin-right": "12px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.getRejectStages.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("完成状态")]
                              ),
                              _vm.selectionRows.length
                                ? _c(
                                    "a-menu",
                                    {
                                      attrs: { slot: "overlay" },
                                      slot: "overlay",
                                    },
                                    [
                                      _vm.selectionRows.some(
                                        (item) => item.taskStatus !== "已完成"
                                      ) &&
                                      _vm.selectionRows.every(
                                        (item) => item.stageName != "甲方反馈"
                                      )
                                        ? _c(
                                            "a-menu-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleTaskFine(
                                                    false
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("div", [
                                                _c("span", {
                                                  staticClass: "circular",
                                                  staticStyle: {
                                                    background: "green",
                                                    "margin-left": "3px",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "5px",
                                                    },
                                                  },
                                                  [_vm._v("已完成")]
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.selectKeys.every(
                                        (item) => item.taskStatus == "已完成"
                                      ) &&
                                      (_vm.isTongChou || _vm.isAdmin)
                                        ? _c(
                                            "a-menu-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleTaskStart(
                                                    "list"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("div", [
                                                _c("span", {
                                                  staticClass: "circular",
                                                  staticStyle: {
                                                    background: "red",
                                                    "margin-left": "3px",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "5px",
                                                    },
                                                  },
                                                  [_vm._v("可开始")]
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.selectionRows.every(
                                        (item) => item.taskStatus !== "已完成"
                                      ) &&
                                      _vm.selectionRows.every(
                                        (item) => item.stageName == "甲方反馈"
                                      )
                                        ? _c(
                                            "a-menu-item",
                                            {
                                              staticClass: "status-item",
                                              attrs: {
                                                disabled:
                                                  _vm.selectionRows.some(
                                                    (item) => item.hasFeedback
                                                  ),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleTaskFine(
                                                    false
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "texe-align": "center",
                                                    "padding-left": "10px",
                                                  },
                                                },
                                                [_vm._v("无反馈")]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.selectionRows.every(
                                        (item) => item.taskStatus !== "已完成"
                                      ) &&
                                      _vm.selectionRows.every(
                                        (item) => item.stageName == "甲方反馈"
                                      ) &&
                                      !_vm.rejectShow
                                        ? _c(
                                            "a-menu-item",
                                            {
                                              staticClass: "status-item",
                                              on: {
                                                click: () =>
                                                  (this.rejectShow = true),
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "texe-align": "center",
                                                    "padding-left": "10px",
                                                  },
                                                },
                                                [_vm._v("有反馈")]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.selectionRows.every(
                                        (item) => item.taskStatus !== "已完成"
                                      ) &&
                                      _vm.selectionRows.every(
                                        (item) => item.stageName == "甲方反馈"
                                      ) &&
                                      _vm.rejectShow
                                        ? _c(
                                            "a-menu-item",
                                            {
                                              staticClass: "status-item",
                                              on: {
                                                click: () =>
                                                  _vm.handleTaskFine(true),
                                              },
                                            },
                                            [
                                              _c("div", {
                                                staticClass: "circular",
                                                staticStyle: {
                                                  background: "green",
                                                  "margin-left": "3px",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "5px",
                                                  },
                                                },
                                                [_vm._v("已完成")]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.selectionRows.every(
                                        (item) => item.taskStatus !== "已完成"
                                      ) &&
                                      _vm.selectionRows.every(
                                        (item) => item.taskStatus !== "驳回"
                                      ) &&
                                      _vm.selectionRows.every(
                                        (item) => item.stageName !== "给稿"
                                      ) &&
                                      _vm.rejectStages.length > 0 &&
                                      _vm.rejectShow
                                        ? [
                                            _vm.rejectStages.length > 1
                                              ? _vm._l(
                                                  _vm.rejectStages,
                                                  function (item, idx) {
                                                    return _c(
                                                      "a-menu-item",
                                                      { key: idx },
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            on: {
                                                              change: (e) => {
                                                                _vm.onChange(
                                                                  e,
                                                                  item
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                item.checked,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "checked",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.checked",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "a-dropdown",
                                                              {
                                                                attrs: {
                                                                  trigger: [
                                                                    "hover",
                                                                  ],
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "line-height":
                                                                          "30px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "驳回-" +
                                                                        _vm._s(
                                                                          item.stageName
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        position:
                                                                          "absolute",
                                                                        top: "-4vh",
                                                                        left: "4.5vw",
                                                                        "box-shadow":
                                                                          "0 2px 8px rgb(0 0 0 / 15%)",
                                                                      },
                                                                    attrs: {
                                                                      slot: "overlay",
                                                                    },
                                                                    slot: "overlay",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "a-menu",
                                                                      [
                                                                        _c(
                                                                          "a-menu-item",
                                                                          {
                                                                            key: "HIGH",
                                                                            staticStyle:
                                                                              {
                                                                                height:
                                                                                  "30px",
                                                                                "line-height":
                                                                                  "30px",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.priorityChange(
                                                                                    item,
                                                                                    "高"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "高\n                          "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "a-menu-item",
                                                                          {
                                                                            key: "MIDDLE",
                                                                            staticStyle:
                                                                              {
                                                                                height:
                                                                                  "30px",
                                                                                "line-height":
                                                                                  "30px",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.priorityChange(
                                                                                    item,
                                                                                    "普通"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "普通\n                          "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "a-menu-item",
                                                                          {
                                                                            key: "LOW",
                                                                            staticStyle:
                                                                              {
                                                                                height:
                                                                                  "30px",
                                                                                "line-height":
                                                                                  "30px",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.priorityChange(
                                                                                    item,
                                                                                    "低"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "低\n                          "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                )
                                              : _vm._l(
                                                  _vm.rejectStages,
                                                  function (item, idx) {
                                                    return _c(
                                                      "a-sub-menu",
                                                      { key: "submenu" + idx },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                              "align-items":
                                                                "center",
                                                              "line-height":
                                                                "30px",
                                                              "min-width":
                                                                "100%",
                                                            },
                                                            attrs: {
                                                              slot: "title",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.priorityChange(
                                                                  item,
                                                                  "普通"
                                                                )
                                                              },
                                                            },
                                                            slot: "title",
                                                          },
                                                          [
                                                            _c("div", {
                                                              staticClass:
                                                                "statusPointer",
                                                              style: {
                                                                background:
                                                                  "orange",
                                                              },
                                                            }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "2px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " 驳回-" +
                                                                    _vm._s(
                                                                      item.stageName
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "a-menu-item",
                                                          {
                                                            key: "HIGH",
                                                            staticStyle: {
                                                              "line-height":
                                                                "30px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.priorityChange(
                                                                  item,
                                                                  "高"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "高\n                  "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "a-menu-item",
                                                          {
                                                            key: "MIDDLE",
                                                            staticStyle: {
                                                              "line-height":
                                                                "30px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.priorityChange(
                                                                  item,
                                                                  "普通"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "普通\n                  "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "a-menu-item",
                                                          {
                                                            key: "LOW",
                                                            staticStyle: {
                                                              "line-height":
                                                                "30px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.priorityChange(
                                                                  item,
                                                                  "低"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "低\n                  "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                          ]
                                        : _vm._e(),
                                      _vm.rejectStages.length > 1 &&
                                      _vm.selectionRows.every(
                                        (item) => item.taskStatus !== "已完成"
                                      ) &&
                                      _vm.selectionRows.every(
                                        (item) => item.taskStatus !== "驳回"
                                      ) &&
                                      _vm.rejectShow
                                        ? _c(
                                            "a-menu-item",
                                            {
                                              staticStyle: {
                                                "background-color":
                                                  "#fff !important",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "task-btn" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "50%",
                                                      },
                                                      on: {
                                                        click: () => {
                                                          this.visible =
                                                            !this.visible
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  取消\n                "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      style: `color:${_vm.color};width:50%`,
                                                      on: {
                                                        click: _vm.submitTask,
                                                      },
                                                    },
                                                    [_vm._v("确定")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.viewType == "calendar"
            ? _c(
                "div",
                { staticStyle: { width: "0", height: "0", opacity: "0" } },
                [
                  _vm.viewType != "list" &&
                  (_vm.checkedFuncList.indexOf("排期") > -1 || _vm.funcShow)
                    ? _c(
                        "div",
                        { staticClass: "work-left" },
                        [
                          !_vm.workDayValueShow
                            ? _c("div", [
                                _c("span", { staticClass: "work-day" }, [
                                  _vm._v("通常可工作日："),
                                ]),
                                _c("span", { staticClass: "work-day-value" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "work-day-value",
                                      on: {
                                        click: function ($event) {
                                          return _vm.workChangeEdit("day")
                                        },
                                      },
                                    },
                                    _vm._l(
                                      Array.from(_vm.workDays || []).sort(
                                        (a, b) => {
                                          return a - b
                                        }
                                      ),
                                      function (item, index) {
                                        return _c("span", { key: item }, [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                item == 1
                                                  ? "周一"
                                                  : item == 2
                                                  ? "周二"
                                                  : item == 3
                                                  ? "周三"
                                                  : item == 4
                                                  ? "周四"
                                                  : item == 5
                                                  ? "周五"
                                                  : item == 6
                                                  ? "周六"
                                                  : item == 7
                                                  ? "周日"
                                                  : item == -1
                                                  ? "跳过中国法定节假日"
                                                  : ""
                                              ) +
                                              _vm._s(
                                                index == _vm.workDays.length - 1
                                                  ? ""
                                                  : "，"
                                              ) +
                                              "\n              "
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                  !_vm.workDayNumValueShow
                                    ? _c("span", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "work-day-num",
                                            on: {
                                              click: function ($event) {
                                                return _vm.workChangeEdit("num")
                                              },
                                            },
                                          },
                                          [_vm._v("可安排工作数量")]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ])
                            : _c("a-input-number", {
                                ref: "workInput",
                                staticStyle: {
                                  width: "200px",
                                  "margin-left": "15px",
                                },
                                attrs: {
                                  min: 0,
                                  placeholder: "工作日可安排数量",
                                },
                                on: { blur: _vm.workEdit },
                                model: {
                                  value: _vm.workdayAvailableCount,
                                  callback: function ($$v) {
                                    _vm.workdayAvailableCount = $$v
                                  },
                                  expression: "workdayAvailableCount",
                                },
                              }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm.viewType == "calendar"
            ? _c(
                "div",
                { staticStyle: { width: "0", height: "0", opacity: "0" } },
                [
                  _vm.viewType != "list" &&
                  (_vm.checkedFuncList.indexOf("排期") > -1 || _vm.funcShow)
                    ? _c(
                        "div",
                        { staticClass: "work-right" },
                        [
                          _c(
                            "a-tooltip",
                            {
                              staticStyle: { "margin-left": "5px" },
                              attrs: {
                                overlayClassName: "work-tooltip",
                                placement: "top",
                              },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(!_vm.isSleep ? "取消请假" : "请假")
                                  ),
                                ]),
                              ]),
                              _c("span", {
                                staticClass: "iconfont icon-shangban1 icon",
                                style: _vm.getWorkStyle(),
                                on: {
                                  click: function ($event) {
                                    _vm.isHoliDay && _vm.isSleep
                                      ? () => {}
                                      : _vm.setVacation(
                                          !_vm.isSleep ? "0" : "HOLIDAY"
                                        )
                                  },
                                },
                              }),
                            ],
                            2
                          ),
                          _c(
                            "a-tooltip",
                            {
                              staticStyle: { "margin-left": "15px" },
                              attrs: {
                                overlayClassName: "work-tooltip",
                                placement: "top",
                              },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c("span", [_vm._v("工作")]),
                              ]),
                              _c("span", {
                                staticClass: "iconfont icon-shangban icon",
                                style: _vm.isWorkDay
                                  ? "color:rgba(0,0,0,0.25);cursor:no-drop;"
                                  : "",
                                on: {
                                  click: function ($event) {
                                    _vm.isWorkDay
                                      ? () => {}
                                      : _vm.setVacation("WORK")
                                  },
                                },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm.isDesktop()
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "mytask:list:button:search_user",
                      expression: "'mytask:list:button:search_user'",
                    },
                  ],
                  staticClass: "table-page-search-wrapper",
                  attrs: { slot: "search" },
                  slot: "search",
                },
                [
                  _vm.viewType != "list" &&
                  _vm.viewType != "confirm-list" &&
                  (_vm.isAdmin ||
                    (_vm.userInfo.roles &&
                      _vm.userInfo.roles.some(
                        (item) => item.roleCode == "zhuanjia"
                      )))
                    ? _c(
                        "a-select",
                        {
                          staticStyle: {
                            width: "380px",
                            "margin-right": "15px",
                          },
                          attrs: {
                            mode: "multiple",
                            showArrow: "",
                            "not-found-content": null,
                            placeholder: "请输入人员昵称",
                            "option-filter-prop": "label",
                            maxTagCount: 3,
                          },
                          on: {
                            search: _vm.userSearch,
                            change: _vm.searchQueryForm,
                          },
                          model: {
                            value: _vm.userIds,
                            callback: function ($$v) {
                              _vm.userIds = $$v
                            },
                            expression: "userIds",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "suffixIcon" },
                              slot: "suffixIcon",
                            },
                            [
                              _c(
                                "a-popover",
                                {
                                  attrs: {
                                    placement: "bottom",
                                    transitionName: "",
                                    overlayClassName:
                                      "filter-pop filter-pop-task",
                                    trigger: "click",
                                  },
                                  on: { click: _vm.getFilterTypes },
                                  model: {
                                    value: _vm.popoverVis,
                                    callback: function ($$v) {
                                      _vm.popoverVis = $$v
                                    },
                                    expression: "popoverVis",
                                  },
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "content" },
                                    [
                                      _c(
                                        "a-spin",
                                        {
                                          attrs: {
                                            spinning: _vm.filterLoading,
                                          },
                                        },
                                        [
                                          _c(
                                            "a-collapse",
                                            {
                                              attrs: { bordered: false },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "expandIcon",
                                                    fn: function (props) {
                                                      return [
                                                        _c("a-icon", {
                                                          attrs: {
                                                            type: "caret-right",
                                                            rotate:
                                                              props.isActive
                                                                ? 90
                                                                : 0,
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                766930589
                                              ),
                                              model: {
                                                value: _vm.activeKey,
                                                callback: function ($$v) {
                                                  _vm.activeKey = $$v
                                                },
                                                expression: "activeKey",
                                              },
                                            },
                                            [
                                              _c(
                                                "a-collapse-panel",
                                                {
                                                  key: "1",
                                                  staticStyle: {
                                                    "padding-left": "25px",
                                                  },
                                                  attrs: { header: "我的收藏" },
                                                },
                                                _vm._l(
                                                  _vm.filterList,
                                                  function (item, index) {
                                                    return _c(
                                                      "p",
                                                      { key: index },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "star-span",
                                                          },
                                                          [
                                                            _c("a-icon", {
                                                              style:
                                                                "color:" +
                                                                _vm.color,
                                                              attrs: {
                                                                type: "star",
                                                                theme: "filled",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.starClick(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "filter-push-span",
                                                            staticStyle: {
                                                              "margin-left":
                                                                "3px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.filterListPush(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.name)
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "filter-div",
                                              staticStyle: {
                                                "padding-left": "25px",
                                                "padding-bottom": "0",
                                                "border-bottom":
                                                  "1px solid #d9d9d9",
                                                "font-size": "14px",
                                              },
                                            },
                                            [
                                              _c(
                                                "a-row",
                                                {
                                                  directives: [
                                                    {
                                                      name: "has",
                                                      rawName: "v-has",
                                                      value:
                                                        "myTasks::button:search",
                                                      expression:
                                                        "'myTasks::button:search'",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    "padding-left": "17px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "filter-label",
                                                        },
                                                        [_vm._v("他人日历：")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "15px",
                                                      },
                                                      attrs: { span: 18 },
                                                    },
                                                    [
                                                      _c(
                                                        "a-select",
                                                        {
                                                          staticStyle: {
                                                            width: "200px",
                                                            position:
                                                              "relative",
                                                          },
                                                          attrs: {
                                                            showSearch: "",
                                                            allowClear: "",
                                                            showArrow: "",
                                                            placeholder:
                                                              "请选择/输入人名",
                                                            "option-filter-prop":
                                                              "label",
                                                          },
                                                          on: {
                                                            search:
                                                              _vm.userSearch,
                                                            change:
                                                              _vm.toOtherCalender,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.skipUserId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.skipUserId =
                                                                $$v
                                                            },
                                                            expression:
                                                              "skipUserId",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.searchUsers,
                                                          function (user) {
                                                            return _c(
                                                              "a-select-option",
                                                              {
                                                                key: user.userId,
                                                                attrs: {
                                                                  label:
                                                                    user.userName,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "100%",
                                                                        "line-height":
                                                                          "30px",
                                                                        display:
                                                                          "flex",
                                                                        color:
                                                                          "rgba(0, 0, 0, 0.65)",
                                                                        "justify-content":
                                                                          "center",
                                                                        "align-items":
                                                                          "center",
                                                                      },
                                                                    attrs: {
                                                                      target:
                                                                        "_blank",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("img", {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "30px",
                                                                          height:
                                                                            "30px",
                                                                          "border-radius":
                                                                            "50%",
                                                                        },
                                                                      attrs: {
                                                                        src: user.avatar,
                                                                        alt: "",
                                                                      },
                                                                    }),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "100px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            user.userName
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-row",
                                                {
                                                  directives: [
                                                    {
                                                      name: "has",
                                                      rawName: "v-has",
                                                      value:
                                                        "myTasks::button:search",
                                                      expression:
                                                        "'myTasks::button:search'",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    "padding-left": "17px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "filter-label",
                                                        },
                                                        [_vm._v("人员昵称：")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "15px",
                                                      },
                                                      attrs: { span: 18 },
                                                    },
                                                    [
                                                      !_vm.isSkip
                                                        ? _c(
                                                            "a-select",
                                                            {
                                                              staticStyle: {
                                                                width: "200px",
                                                              },
                                                              attrs: {
                                                                mode: "multiple",
                                                                showArrow: "",
                                                                "not-found-content":
                                                                  null,
                                                                placeholder:
                                                                  "请选择/输入人名",
                                                                "option-filter-prop":
                                                                  "label",
                                                                maxTagCount: 1,
                                                              },
                                                              on: {
                                                                search:
                                                                  _vm.userSearch,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.userIds,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.userIds =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "userIds",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.searchUsers,
                                                              function (user) {
                                                                return _c(
                                                                  "a-select-option",
                                                                  {
                                                                    key: user.userId,
                                                                    attrs: {
                                                                      label:
                                                                        user.userName,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          " +
                                                                        _vm._s(
                                                                          user.userName
                                                                        ) +
                                                                        "\n                        "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-row",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "17px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "filter-label",
                                                        },
                                                        [_vm._v("阶段：")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "15px",
                                                      },
                                                      attrs: { span: 18 },
                                                    },
                                                    [
                                                      _c(
                                                        "a-select",
                                                        {
                                                          staticStyle: {
                                                            width: "200px",
                                                          },
                                                          attrs: {
                                                            mode: "multiple",
                                                            showArrow: "",
                                                            placeholder:
                                                              "选择阶段",
                                                            maxTagCount: 1,
                                                          },
                                                          model: {
                                                            value: _vm.stageIds,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.stageIds = $$v
                                                            },
                                                            expression:
                                                              "stageIds",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.filterStageList,
                                                          function (stage) {
                                                            return _c(
                                                              "a-select-option",
                                                              {
                                                                key: stage.stageId,
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      stage.stageName
                                                                    ) +
                                                                    "\n                        "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-row",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "17px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "filter-label",
                                                        },
                                                        [_vm._v("平台：")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "15px",
                                                      },
                                                      attrs: { span: 18 },
                                                    },
                                                    [
                                                      _c(
                                                        "a-select",
                                                        {
                                                          staticStyle: {
                                                            width: "200px",
                                                          },
                                                          attrs: {
                                                            mode: "multiple",
                                                            showArrow: "",
                                                            placeholder:
                                                              "选择平台",
                                                            maxTagCount: 1,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.platformIds,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.platformIds =
                                                                $$v
                                                            },
                                                            expression:
                                                              "platformIds",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.filterPlatformList,
                                                          function (stage) {
                                                            return _c(
                                                              "a-select-option",
                                                              {
                                                                key: stage.platformId,
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      stage.platformName
                                                                    ) +
                                                                    "\n                        "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-row",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "17px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "filter-label",
                                                        },
                                                        [_vm._v("作品名称：")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "15px",
                                                      },
                                                      attrs: { span: 18 },
                                                    },
                                                    [
                                                      _c("a-input", {
                                                        staticStyle: {
                                                          width: "200px",
                                                        },
                                                        attrs: {
                                                          placeholder:
                                                            "作品名称",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.productionName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.productionName =
                                                              $$v
                                                          },
                                                          expression:
                                                            "productionName",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-row",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "17px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "filter-label",
                                                        },
                                                        [_vm._v("作品序号：")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "15px",
                                                      },
                                                      attrs: { span: 18 },
                                                    },
                                                    [
                                                      _c("a-input-number", {
                                                        staticStyle: {
                                                          width: "200px",
                                                        },
                                                        attrs: {
                                                          placeholder:
                                                            "作品序号",
                                                          min: 0,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.productSequenceNo,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.productSequenceNo =
                                                              $$v
                                                          },
                                                          expression:
                                                            "productSequenceNo",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-row",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "17px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "filter-label",
                                                        },
                                                        [_vm._v("完成状态：")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "15px",
                                                      },
                                                      attrs: { span: 18 },
                                                    },
                                                    [
                                                      _c(
                                                        "a-select",
                                                        {
                                                          staticStyle: {
                                                            width: "200px",
                                                            position:
                                                              "relative",
                                                          },
                                                          attrs: {
                                                            showSearch: "",
                                                            allowClear: "",
                                                            showArrow: "",
                                                            placeholder:
                                                              "请选择完成状态",
                                                            mode: "multiple",
                                                            maxTagCount: 1,
                                                            "option-filter-prop":
                                                              "label",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.selTaskStatus,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.selTaskStatus =
                                                                $$v
                                                            },
                                                            expression:
                                                              "selTaskStatus",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a-select-option",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  "FINISHED",
                                                              },
                                                            },
                                                            [_vm._v("已完成")]
                                                          ),
                                                          _c(
                                                            "a-select-option",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  "STARTED",
                                                              },
                                                            },
                                                            [_vm._v("可开始")]
                                                          ),
                                                          _c(
                                                            "a-select-option",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  "WAITING",
                                                              },
                                                            },
                                                            [_vm._v("等待中")]
                                                          ),
                                                          _c(
                                                            "a-select-option",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  "REJECTING",
                                                              },
                                                            },
                                                            [_vm._v("驳回")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-row",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "17px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "filter-label",
                                                        },
                                                        [_vm._v("备注：")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "15px",
                                                      },
                                                      attrs: { span: 18 },
                                                    },
                                                    [
                                                      _c(
                                                        "a-select",
                                                        {
                                                          staticStyle: {
                                                            width: "200px",
                                                            position:
                                                              "relative",
                                                          },
                                                          attrs: {
                                                            showSearch: "",
                                                            showArrow: "",
                                                            placeholder:
                                                              "请选择",
                                                            "option-filter-prop":
                                                              "label",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.hasNoteStatus,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.hasNoteStatus =
                                                                $$v
                                                            },
                                                            expression:
                                                              "hasNoteStatus",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a-select-option",
                                                            {
                                                              attrs: {
                                                                value: "2",
                                                              },
                                                            },
                                                            [_vm._v("全部")]
                                                          ),
                                                          _c(
                                                            "a-select-option",
                                                            {
                                                              attrs: {
                                                                value: "1",
                                                              },
                                                            },
                                                            [_vm._v("有")]
                                                          ),
                                                          _c(
                                                            "a-select-option",
                                                            {
                                                              attrs: {
                                                                value: "0",
                                                              },
                                                            },
                                                            [_vm._v("无")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-row",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "17px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "filter-label",
                                                        },
                                                        [_vm._v("完成情况：")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "15px",
                                                        "line-height": "32px",
                                                      },
                                                      attrs: { span: 18 },
                                                    },
                                                    [
                                                      _c(
                                                        "a-select",
                                                        {
                                                          staticStyle: {
                                                            width: "200px",
                                                            position:
                                                              "relative",
                                                          },
                                                          attrs: {
                                                            showSearch: "",
                                                            allowClear: "",
                                                            showArrow: "",
                                                            placeholder:
                                                              "请选择完成情况",
                                                            mode: "multiple",
                                                            maxTagCount: 1,
                                                            "option-filter-prop":
                                                              "label",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.finishStatus,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.finishStatus =
                                                                $$v
                                                            },
                                                            expression:
                                                              "finishStatus",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a-select-option",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  "ADVANCE",
                                                              },
                                                            },
                                                            [_vm._v("提前")]
                                                          ),
                                                          _c(
                                                            "a-select-option",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  "ON_TIME",
                                                              },
                                                            },
                                                            [_vm._v("按时")]
                                                          ),
                                                          _c(
                                                            "a-select-option",
                                                            {
                                                              attrs: {
                                                                value: "DELAY",
                                                              },
                                                            },
                                                            [_vm._v("拖稿")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "filter-div",
                                              staticStyle: {
                                                display: "flex",
                                                "justify-content":
                                                  "space-between",
                                                "min-width": "190px",
                                                "font-size": "14px",
                                                "padding-left": "38px",
                                                "padding-right": "42px",
                                              },
                                            },
                                            [
                                              _c(
                                                "a-popconfirm",
                                                {
                                                  attrs: {
                                                    overlayClassName:
                                                      "filter-pop-fileter-name",
                                                    "ok-text": "确认",
                                                    placement: "bottomLeft",
                                                    "cancel-text": "取消",
                                                  },
                                                  on: {
                                                    confirm: _vm.addFilter,
                                                  },
                                                },
                                                [
                                                  _c("span", {
                                                    attrs: { slot: "icon" },
                                                    slot: "icon",
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      attrs: { slot: "title" },
                                                      slot: "title",
                                                    },
                                                    [
                                                      _c("a-input", {
                                                        staticStyle: {
                                                          width: "180px",
                                                        },
                                                        attrs: {
                                                          placeholder:
                                                            "请输入收藏名称",
                                                        },
                                                        model: {
                                                          value: _vm.filterName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.filterName = $$v
                                                          },
                                                          expression:
                                                            "filterName",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "add-filter",
                                                    },
                                                    [_vm._v("+ 收藏当前条件")]
                                                  ),
                                                ]
                                              ),
                                              _c("div", [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "add-filter-btn",
                                                    staticStyle: {
                                                      color:
                                                        "rgba(0, 0, 0, 0.65)",
                                                      "margin-right": "5px",
                                                    },
                                                    on: {
                                                      click: _vm.removeFilter,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      重置\n                    "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "add-filter-btn",
                                                    style:
                                                      "color: #fff;background-color:" +
                                                      _vm.color,
                                                    on: {
                                                      click:
                                                        _vm.searchAndSaveQueryForm,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      搜索\n                    "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "search-user-span",
                                      style:
                                        _vm.userIds.length ||
                                        _vm.stageIds.length ||
                                        _vm.platformIds.length ||
                                        _vm.productionName ||
                                        _vm.hasNoteStatus ||
                                        _vm.selTaskStatus.length ||
                                        _vm.finishStatus.length
                                          ? `color:${_vm.color}`
                                          : "",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.openPop.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("高级搜索")]
                                  ),
                                ],
                                2
                              ),
                              _c("a-icon", {
                                staticClass: "search-user",
                                staticStyle: {
                                  "margin-left": "7px",
                                  "font-size": "14px",
                                },
                                attrs: { type: "search" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.searchQueryForm.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._l(_vm.searchUsers, function (user) {
                            return _c(
                              "a-select-option",
                              {
                                key: user.userId,
                                attrs: { label: user.userName },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(user.userName) +
                                    "\n        "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    : _vm.viewType != "list" &&
                      _vm.viewType != "confirm-list" &&
                      !_vm.isAdmin &&
                      _vm.userInfo.roles &&
                      _vm.userInfo.roles.every(
                        (item) => item.roleCode != "zhuanjia"
                      )
                    ? _c(
                        "a-input",
                        {
                          staticStyle: {
                            width: "380px",
                            "margin-right": "15px",
                          },
                          attrs: { placeholder: "请输入作品名称" },
                          on: {
                            change: _vm.searchQueryForm,
                            pressEnter: _vm.searchQueryForm,
                          },
                          model: {
                            value: _vm.productionName,
                            callback: function ($$v) {
                              _vm.productionName = $$v
                            },
                            expression: "productionName",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "suffix" }, slot: "suffix" },
                            [
                              _c(
                                "a-popover",
                                {
                                  attrs: {
                                    placement: "bottom",
                                    transitionName: "",
                                    overlayClassName:
                                      "filter-pop filter-pop-task",
                                    trigger: "click",
                                  },
                                  on: { click: _vm.getFilterTypes },
                                  model: {
                                    value: _vm.popoverVis,
                                    callback: function ($$v) {
                                      _vm.popoverVis = $$v
                                    },
                                    expression: "popoverVis",
                                  },
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "content" },
                                    [
                                      _c(
                                        "a-spin",
                                        {
                                          attrs: {
                                            spinning: _vm.filterLoading,
                                          },
                                        },
                                        [
                                          _c(
                                            "a-collapse",
                                            {
                                              attrs: { bordered: false },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "expandIcon",
                                                    fn: function (props) {
                                                      return [
                                                        _c("a-icon", {
                                                          attrs: {
                                                            type: "caret-right",
                                                            rotate:
                                                              props.isActive
                                                                ? 90
                                                                : 0,
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                766930589
                                              ),
                                              model: {
                                                value: _vm.activeKey,
                                                callback: function ($$v) {
                                                  _vm.activeKey = $$v
                                                },
                                                expression: "activeKey",
                                              },
                                            },
                                            [
                                              _c(
                                                "a-collapse-panel",
                                                {
                                                  key: "1",
                                                  staticStyle: {
                                                    "padding-left": "25px",
                                                  },
                                                  attrs: { header: "我的收藏" },
                                                },
                                                _vm._l(
                                                  _vm.filterList,
                                                  function (item, index) {
                                                    return _c(
                                                      "p",
                                                      { key: index },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "star-span",
                                                          },
                                                          [
                                                            _c("a-icon", {
                                                              style:
                                                                "color:" +
                                                                _vm.color,
                                                              attrs: {
                                                                type: "star",
                                                                theme: "filled",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.starClick(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "filter-push-span",
                                                            staticStyle: {
                                                              "margin-left":
                                                                "3px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.filterListPush(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.name)
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "filter-div",
                                              staticStyle: {
                                                "padding-left": "25px",
                                                "padding-bottom": "0",
                                                "border-bottom":
                                                  "1px solid #d9d9d9",
                                                "font-size": "14px",
                                              },
                                            },
                                            [
                                              _c(
                                                "a-row",
                                                {
                                                  directives: [
                                                    {
                                                      name: "has",
                                                      rawName: "v-has",
                                                      value:
                                                        "myTasks::button:search",
                                                      expression:
                                                        "'myTasks::button:search'",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    "padding-left": "17px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "filter-label",
                                                        },
                                                        [_vm._v("他人日历：")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "15px",
                                                      },
                                                      attrs: { span: 18 },
                                                    },
                                                    [
                                                      _c(
                                                        "a-select",
                                                        {
                                                          staticStyle: {
                                                            width: "200px",
                                                            position:
                                                              "relative",
                                                          },
                                                          attrs: {
                                                            showSearch: "",
                                                            allowClear: "",
                                                            showArrow: "",
                                                            placeholder:
                                                              "请选择/输入人名",
                                                            "option-filter-prop":
                                                              "label",
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.toOtherCalender,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.skipUserId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.skipUserId =
                                                                $$v
                                                            },
                                                            expression:
                                                              "skipUserId",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.allUsers,
                                                          function (user) {
                                                            return _c(
                                                              "a-select-option",
                                                              {
                                                                key: user.userId,
                                                                attrs: {
                                                                  label:
                                                                    user.userName,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "100%",
                                                                        "line-height":
                                                                          "30px",
                                                                        display:
                                                                          "flex",
                                                                        color:
                                                                          "rgba(0, 0, 0, 0.65)",
                                                                        "justify-content":
                                                                          "center",
                                                                        "align-items":
                                                                          "center",
                                                                      },
                                                                    attrs: {
                                                                      target:
                                                                        "_blank",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("img", {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "30px",
                                                                          height:
                                                                            "30px",
                                                                          "border-radius":
                                                                            "50%",
                                                                        },
                                                                      attrs: {
                                                                        src: user.avatar,
                                                                        alt: "",
                                                                      },
                                                                    }),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "100px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            user.userName
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-row",
                                                {
                                                  directives: [
                                                    {
                                                      name: "has",
                                                      rawName: "v-has",
                                                      value:
                                                        "myTasks::button:search",
                                                      expression:
                                                        "'myTasks::button:search'",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    "padding-left": "17px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "filter-label",
                                                        },
                                                        [_vm._v("人员昵称：")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "15px",
                                                      },
                                                      attrs: { span: 18 },
                                                    },
                                                    [
                                                      !_vm.isSkip
                                                        ? _c(
                                                            "a-select",
                                                            {
                                                              staticStyle: {
                                                                width: "200px",
                                                              },
                                                              attrs: {
                                                                mode: "multiple",
                                                                showArrow: "",
                                                                "not-found-content":
                                                                  null,
                                                                placeholder:
                                                                  "请选择/输入人名",
                                                                "option-filter-prop":
                                                                  "label",
                                                                maxTagCount: 1,
                                                              },
                                                              on: {
                                                                search:
                                                                  _vm.userSearch,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.userIds,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.userIds =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "userIds",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.searchUsers,
                                                              function (user) {
                                                                return _c(
                                                                  "a-select-option",
                                                                  {
                                                                    key: user.userId,
                                                                    attrs: {
                                                                      label:
                                                                        user.userName,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          " +
                                                                        _vm._s(
                                                                          user.userName
                                                                        ) +
                                                                        "\n                        "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-row",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "17px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "filter-label",
                                                        },
                                                        [_vm._v("阶段：")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "15px",
                                                      },
                                                      attrs: { span: 18 },
                                                    },
                                                    [
                                                      _c(
                                                        "a-select",
                                                        {
                                                          staticStyle: {
                                                            width: "200px",
                                                          },
                                                          attrs: {
                                                            mode: "multiple",
                                                            showArrow: "",
                                                            placeholder:
                                                              "选择阶段",
                                                            maxTagCount: 1,
                                                          },
                                                          model: {
                                                            value: _vm.stageIds,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.stageIds = $$v
                                                            },
                                                            expression:
                                                              "stageIds",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.filterStageList,
                                                          function (stage) {
                                                            return _c(
                                                              "a-select-option",
                                                              {
                                                                key: stage.stageId,
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      stage.stageName
                                                                    ) +
                                                                    "\n                        "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-row",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "17px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "filter-label",
                                                        },
                                                        [_vm._v("平台：")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "15px",
                                                      },
                                                      attrs: { span: 18 },
                                                    },
                                                    [
                                                      _c(
                                                        "a-select",
                                                        {
                                                          staticStyle: {
                                                            width: "200px",
                                                          },
                                                          attrs: {
                                                            mode: "multiple",
                                                            showArrow: "",
                                                            placeholder:
                                                              "选择阶段",
                                                            maxTagCount: 1,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.platformIds,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.platformIds =
                                                                $$v
                                                            },
                                                            expression:
                                                              "platformIds",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.filterPlatformList,
                                                          function (stage) {
                                                            return _c(
                                                              "a-select-option",
                                                              {
                                                                key: stage.platformId,
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      stage.platformName
                                                                    ) +
                                                                    "\n                        "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-row",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "17px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "filter-label",
                                                        },
                                                        [_vm._v("作品名称：")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "15px",
                                                      },
                                                      attrs: { span: 18 },
                                                    },
                                                    [
                                                      _c("a-input", {
                                                        staticStyle: {
                                                          width: "200px",
                                                        },
                                                        attrs: {
                                                          placeholder:
                                                            "作品名称",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.productionName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.productionName =
                                                              $$v
                                                          },
                                                          expression:
                                                            "productionName",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-row",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "17px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "filter-label",
                                                        },
                                                        [_vm._v("作品序号：")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "15px",
                                                      },
                                                      attrs: { span: 18 },
                                                    },
                                                    [
                                                      _c("a-input-number", {
                                                        staticStyle: {
                                                          width: "200px",
                                                        },
                                                        attrs: {
                                                          placeholder:
                                                            "作品序号",
                                                          min: 0,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.productSequenceNo,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.productSequenceNo =
                                                              $$v
                                                          },
                                                          expression:
                                                            "productSequenceNo",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-row",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "17px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "filter-label",
                                                        },
                                                        [_vm._v("完成状态：")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "15px",
                                                      },
                                                      attrs: { span: 18 },
                                                    },
                                                    [
                                                      _c(
                                                        "a-select",
                                                        {
                                                          staticStyle: {
                                                            width: "200px",
                                                            position:
                                                              "relative",
                                                          },
                                                          attrs: {
                                                            showSearch: "",
                                                            allowClear: "",
                                                            showArrow: "",
                                                            placeholder:
                                                              "请选择完成状态",
                                                            mode: "multiple",
                                                            maxTagCount: 1,
                                                            "option-filter-prop":
                                                              "label",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.selTaskStatus,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.selTaskStatus =
                                                                $$v
                                                            },
                                                            expression:
                                                              "selTaskStatus",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a-select-option",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  "FINISHED",
                                                              },
                                                            },
                                                            [_vm._v("已完成")]
                                                          ),
                                                          _c(
                                                            "a-select-option",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  "STARTED",
                                                              },
                                                            },
                                                            [_vm._v("可开始")]
                                                          ),
                                                          _c(
                                                            "a-select-option",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  "WAITING",
                                                              },
                                                            },
                                                            [_vm._v("等待中")]
                                                          ),
                                                          _c(
                                                            "a-select-option",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  "REJECTING",
                                                              },
                                                            },
                                                            [_vm._v("驳回")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-row",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "17px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "filter-label",
                                                        },
                                                        [_vm._v("备注：")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "15px",
                                                      },
                                                      attrs: { span: 18 },
                                                    },
                                                    [
                                                      _c(
                                                        "a-select",
                                                        {
                                                          staticStyle: {
                                                            width: "200px",
                                                            position:
                                                              "relative",
                                                          },
                                                          attrs: {
                                                            showSearch: "",
                                                            allowClear: "",
                                                            showArrow: "",
                                                            placeholder:
                                                              "请选择",
                                                            "option-filter-prop":
                                                              "label",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.hasNoteStatus,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.hasNoteStatus =
                                                                $$v
                                                            },
                                                            expression:
                                                              "hasNoteStatus",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a-select-option",
                                                            {
                                                              attrs: {
                                                                value: "2",
                                                              },
                                                            },
                                                            [_vm._v("全部")]
                                                          ),
                                                          _c(
                                                            "a-select-option",
                                                            {
                                                              attrs: {
                                                                value: "1",
                                                              },
                                                            },
                                                            [_vm._v("有")]
                                                          ),
                                                          _c(
                                                            "a-select-option",
                                                            {
                                                              attrs: {
                                                                value: "0",
                                                              },
                                                            },
                                                            [_vm._v("无")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-row",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "17px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "filter-label",
                                                        },
                                                        [_vm._v("完成情况：")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "15px",
                                                        "line-height": "32px",
                                                      },
                                                      attrs: { span: 18 },
                                                    },
                                                    [
                                                      _c(
                                                        "a-select",
                                                        {
                                                          staticStyle: {
                                                            width: "200px",
                                                            position:
                                                              "relative",
                                                          },
                                                          attrs: {
                                                            showSearch: "",
                                                            allowClear: "",
                                                            showArrow: "",
                                                            placeholder:
                                                              "请选择完成情况",
                                                            mode: "multiple",
                                                            maxTagCount: 1,
                                                            "option-filter-prop":
                                                              "label",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.finishStatus,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.finishStatus =
                                                                $$v
                                                            },
                                                            expression:
                                                              "finishStatus",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a-select-option",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  "ADVANCE",
                                                              },
                                                            },
                                                            [_vm._v("提前")]
                                                          ),
                                                          _c(
                                                            "a-select-option",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  "ON_TIME",
                                                              },
                                                            },
                                                            [_vm._v("按时")]
                                                          ),
                                                          _c(
                                                            "a-select-option",
                                                            {
                                                              attrs: {
                                                                value: "DELAY",
                                                              },
                                                            },
                                                            [_vm._v("拖稿")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "filter-div",
                                              staticStyle: {
                                                display: "flex",
                                                "justify-content":
                                                  "space-between",
                                                "min-width": "190px",
                                                "font-size": "14px",
                                                "padding-left": "38px",
                                                "padding-right": "42px",
                                              },
                                            },
                                            [
                                              _c(
                                                "a-popconfirm",
                                                {
                                                  attrs: {
                                                    overlayClassName:
                                                      "filter-pop-fileter-name",
                                                    "ok-text": "确认",
                                                    placement: "bottomLeft",
                                                    "cancel-text": "取消",
                                                  },
                                                  on: {
                                                    confirm: _vm.addFilter,
                                                  },
                                                },
                                                [
                                                  _c("span", {
                                                    attrs: { slot: "icon" },
                                                    slot: "icon",
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      attrs: { slot: "title" },
                                                      slot: "title",
                                                    },
                                                    [
                                                      _c("a-input", {
                                                        staticStyle: {
                                                          width: "180px",
                                                        },
                                                        attrs: {
                                                          placeholder:
                                                            "请输入收藏名称",
                                                        },
                                                        model: {
                                                          value: _vm.filterName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.filterName = $$v
                                                          },
                                                          expression:
                                                            "filterName",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "add-filter",
                                                    },
                                                    [_vm._v("+ 收藏当前条件")]
                                                  ),
                                                ]
                                              ),
                                              _c("div", [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "add-filter-btn",
                                                    staticStyle: {
                                                      color:
                                                        "rgba(0, 0, 0, 0.65)",
                                                      "margin-right": "5px",
                                                    },
                                                    on: {
                                                      click: _vm.removeFilter,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      重置\n                    "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "add-filter-btn",
                                                    style:
                                                      "color: #fff;background-color:" +
                                                      _vm.color,
                                                    on: {
                                                      click:
                                                        _vm.searchAndSaveQueryForm,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      搜索\n                    "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "search-user-span",
                                      style:
                                        "font-size:12px;" +
                                        (_vm.userIds.length ||
                                        _vm.stageIds.length ||
                                        _vm.platformIds.length ||
                                        _vm.productionName ||
                                        _vm.productSequenceNo ||
                                        _vm.selTaskStatus ||
                                        _vm.hasNoteStatus ||
                                        _vm.finishStatus
                                          ? `color:${_vm.color}`
                                          : ""),
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.openPop.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("高级搜索")]
                                  ),
                                ],
                                2
                              ),
                              _c("a-icon", {
                                staticClass: "search-user",
                                staticStyle: {
                                  "margin-left": "7px",
                                  "font-size": "14px",
                                },
                                attrs: { type: "search" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.searchQueryForm.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.viewType == "confirm-list"
                    ? _c("a-input", {
                        staticStyle: { width: "380px" },
                        attrs: { placeholder: "请输入任务名称" },
                        on: { pressEnter: _vm.confirmListSearch },
                        model: {
                          value: _vm.confirmTaskName,
                          callback: function ($$v) {
                            _vm.confirmTaskName = $$v
                          },
                          expression: "confirmTaskName",
                        },
                      })
                    : _vm._e(),
                  _c(
                    "a-popover",
                    {
                      attrs: {
                        placement: "bottom",
                        transitionName: "",
                        overlayClassName: _vm.isAdmin
                          ? "filter-pop status-pop"
                          : "filter-pop status-pop status-user-pop",
                        trigger: "click",
                      },
                      on: { click: _vm.getFilterTypes },
                      model: {
                        value: _vm.statusPopoverVis,
                        callback: function ($$v) {
                          _vm.statusPopoverVis = $$v
                        },
                        expression: "statusPopoverVis",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "content" },
                        [
                          _c(
                            "a-spin",
                            { attrs: { spinning: _vm.filterLoading } },
                            [
                              _c(
                                "div",
                                { staticClass: "check-list" },
                                [
                                  _c(
                                    "a-row",
                                    {
                                      staticStyle: {
                                        "border-bottom": "1px solid #d9d9d9",
                                        "font-weight": "700",
                                      },
                                    },
                                    [
                                      _c(
                                        "a-col",
                                        {
                                          attrs: { span: _vm.isAdmin ? 9 : 12 },
                                        },
                                        [
                                          _c(
                                            "a-checkbox",
                                            {
                                              attrs: {
                                                indeterminate:
                                                  _vm.indeterminateStatuts,
                                                checked: _vm.checkAllStatus,
                                              },
                                              on: {
                                                change: (e) => {
                                                  _vm.onCheckAllChange(
                                                    e,
                                                    "status"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("完成状态")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        {
                                          attrs: {
                                            span: _vm.isAdmin ? 15 : 12,
                                          },
                                        },
                                        [
                                          _c(
                                            "a-checkbox",
                                            {
                                              attrs: {
                                                indeterminate:
                                                  _vm.indeterminateFunc,
                                                checked: _vm.checkAllFunc,
                                              },
                                              on: {
                                                change: (e) => {
                                                  _vm.onCheckAllChange(
                                                    e,
                                                    "func"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("日历功能")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-row",
                                    {
                                      staticStyle: {
                                        "border-bottom": "1px solid #d9d9d9",
                                      },
                                    },
                                    [
                                      _c(
                                        "a-col",
                                        {
                                          attrs: { span: _vm.isAdmin ? 9 : 12 },
                                        },
                                        [
                                          _c("a-checkbox-group", {
                                            attrs: {
                                              options: _vm.statusOptions,
                                            },
                                            on: {
                                              change: (list) => {
                                                _vm.checkChange(list, "status")
                                              },
                                            },
                                            model: {
                                              value: _vm.checkedStatusList,
                                              callback: function ($$v) {
                                                _vm.checkedStatusList = $$v
                                              },
                                              expression: "checkedStatusList",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        {
                                          attrs: {
                                            span: _vm.isAdmin ? 15 : 12,
                                          },
                                        },
                                        [
                                          _c(
                                            "a-checkbox-group",
                                            {
                                              staticStyle: { width: "100%" },
                                              on: {
                                                change: (list) => {
                                                  _vm.checkChange(list, "func")
                                                },
                                              },
                                              model: {
                                                value: _vm.checkedFuncList,
                                                callback: function ($$v) {
                                                  _vm.checkedFuncList = $$v
                                                },
                                                expression: "checkedFuncList",
                                              },
                                            },
                                            [
                                              _c(
                                                "a-row",
                                                [
                                                  _c(
                                                    "a-col",
                                                    {
                                                      attrs: {
                                                        span: _vm.isAdmin
                                                          ? 13
                                                          : 24,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "a-checkbox",
                                                        {
                                                          attrs: {
                                                            value: "排期",
                                                          },
                                                        },
                                                        [_vm._v("排期")]
                                                      ),
                                                      _c(
                                                        "a-checkbox",
                                                        {
                                                          attrs: {
                                                            value: "节假日",
                                                          },
                                                        },
                                                        [_vm._v("节假日")]
                                                      ),
                                                      _c(
                                                        "a-checkbox",
                                                        {
                                                          attrs: {
                                                            value: "可安排数量",
                                                          },
                                                        },
                                                        [_vm._v("可安排数量")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "filter-div",
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "space-between",
                                        "min-width": "190px",
                                        "font-size": "14px",
                                      },
                                    },
                                    [
                                      _c("div"),
                                      _c("div", [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "add-filter-btn",
                                            staticStyle: {
                                              color: "rgba(0, 0, 0, 0.65)",
                                              "margin-right": "5px",
                                            },
                                            on: { click: _vm.cancelChunck },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    取消\n                  "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "add-filter-btn",
                                            style:
                                              "color: #fff;background-color:" +
                                              _vm.color,
                                            on: { click: _vm.saveFilter },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    确认\n                  "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.viewType != "list" && _vm.viewType != "confirm-list"
                        ? _c(
                            "div",
                            {
                              staticClass: "calendar-menu",
                              on: { click: _vm.openStatusPop },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("a-icon", {
                                    attrs: { type: "menu-unfold" },
                                  }),
                                  _c("span", [_vm._v("功能")]),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm.viewType == "list" &&
                  (_vm.isAdmin ||
                    (_vm.userInfo.roles &&
                      _vm.userInfo.roles.some(
                        (item) => item.roleCode == "zhuanjia"
                      )))
                    ? _c(
                        "a-select",
                        {
                          staticStyle: { width: "380px" },
                          attrs: {
                            mode: "multiple",
                            showArrow: false,
                            "not-found-content": null,
                            placeholder: "请输入人员昵称",
                            "option-filter-prop": "label",
                          },
                          on: {
                            search: _vm.userSearch,
                            change: _vm.searchQueryForm,
                          },
                          model: {
                            value: _vm.userIds,
                            callback: function ($$v) {
                              _vm.userIds = $$v
                            },
                            expression: "userIds",
                          },
                        },
                        _vm._l(_vm.searchUsers, function (user) {
                          return _c(
                            "a-select-option",
                            {
                              key: user.userId,
                              attrs: { label: user.userName },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(user.userName) +
                                  "\n        "
                              ),
                            ]
                          )
                        }),
                        1
                      )
                    : _vm.viewType == "list" &&
                      (!_vm.isAdmin ||
                        (_vm.userInfo.roles &&
                          _vm.userInfo.roles.every(
                            (item) => item.roleCode != "zhuanjia"
                          )))
                    ? _c("a-input", {
                        staticStyle: { width: "380px" },
                        attrs: {
                          allowClear: "",
                          placeholder: "请输入作品名称",
                        },
                        on: {
                          change: _vm.searchQueryForm,
                          pressEnter: _vm.searchQueryForm,
                        },
                        model: {
                          value: _vm.productionName,
                          callback: function ($$v) {
                            _vm.productionName = $$v
                          },
                          expression: "productionName",
                        },
                      })
                    : _vm._e(),
                  _vm.viewType == "list"
                    ? _c(
                        "a",
                        {
                          staticStyle: {
                            "margin-left": "5px",
                            position: "relative",
                            "font-size": "14px",
                            "font-weight": "400",
                          },
                          on: { click: _vm.clearFilter },
                        },
                        [_vm._v("\n        重置筛选条件\n      ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c("tasks-model", {
            ref: "tasksModel",
            on: { closeTasksModel: _vm.closeTasksModel },
          }),
          _vm.viewType == "list"
            ? _c("my-tasks-table", {
                ref: "table",
                attrs: {
                  userIds: _vm.userIds,
                  sortType: _vm.sortType,
                  allUsers: _vm.allUsers,
                  productionName: _vm.productionName,
                },
                on: {
                  setUsers: _vm.setUsers,
                  setRowkeys: _vm.setRowkeys,
                  setProductName: _vm.setProductName,
                },
              })
            : _vm._e(),
          _vm.viewType == "confirm-list"
            ? _c("confirm-tasks-table", {
                ref: "confirmTable",
                attrs: {
                  confirmTaskName: _vm.confirmTaskName,
                  userInfo: _vm.userInfo,
                },
                on: { changeConfirmTableTotal: _vm.changeConfirmTableTotal },
              })
            : _vm._e(),
          _c("manuscript-list", {
            ref: "manuscript",
            attrs: { title: "上传原稿", selectionRows: _vm.selectionRows },
          }),
          _c("sumbmit-modal", {
            ref: "sumbmitModal",
            attrs: { title: "上传完成稿", selectionRows: _vm.selectionRows },
            on: { openTaskModel: _vm.openTaskModel },
          }),
          _c("month-list", {
            ref: "monthList",
            attrs: { allUsers: _vm.allUsers, viewType: _vm.viewType },
            on: {
              changeTaskList: _vm.changeTaskList,
              getTaskTableData: _vm.getTaskTableData,
            },
          }),
          _vm.viewType == "calendar"
            ? _c("panel-calendar", {
                ref: "calendarPanel",
                attrs: {
                  getWorkNum: _vm.getWorkNum,
                  userInfo: _vm.userInfo,
                  filterCheckList: _vm.filterCheckList,
                  calendarType: _vm.calendarType,
                  pushWorkDays: _vm.pushWorkDays,
                  workDays: _vm.workDays,
                  workdayAvailableCount: _vm.workdayAvailableCount,
                  userIds: _vm.userIds,
                  isAdmin: _vm.isAdmin,
                  stageList: _vm.stageList,
                  isView: _vm.isView,
                  isSkip: _vm.isSkip,
                  isMyTask: true,
                  selectKeys: _vm.selectKeys,
                  queryParam: _vm.queryParam,
                },
                on: {
                  setIsWorkEdit: _vm.setIsWorkEdit,
                  setQueryParam: _vm.setQueryParam,
                  setSelectKeys: _vm.setSelectKeys,
                  getTitle: _vm.getTitle,
                  setSkipLoading: _vm.setSkipLoading,
                  changeViewTypeToWeek: _vm.changeViewTypeToWeek,
                  getDate: _vm.getDate,
                  changeFinishDis: _vm.changeFinishDis,
                  getKey: _vm.getKey,
                  setSearchDate: _vm.setSearchDate,
                },
              })
            : _vm._e(),
          _c(
            "a-modal",
            {
              attrs: { title: "请假理由", width: 500 },
              on: { cancel: _vm.handleNoWorkCancel, ok: _vm.handleNoWorkOk },
              model: {
                value: _vm.noWrokVisible,
                callback: function ($$v) {
                  _vm.noWrokVisible = $$v
                },
                expression: "noWrokVisible",
              },
            },
            [
              _c("a-textarea", {
                attrs: { autosize: true, placeholder: "请输入请假理由" },
                model: {
                  value: _vm.noWorkReasson,
                  callback: function ($$v) {
                    _vm.noWorkReasson = $$v
                  },
                  expression: "noWorkReasson",
                },
              }),
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: {
                keyboard: false,
                "mask-closable": false,
                closable: false,
                title: "修改可安排话数",
                width: _vm.scheduleIsDaily ? "35%" : "50%",
              },
              scopedSlots: _vm._u([
                {
                  key: "footer",
                  fn: function () {
                    return [
                      !_vm.workEditable
                        ? _c("a-button", { on: { click: _vm.calcel } }, [
                            _vm._v(" 关闭 "),
                          ])
                        : _c(
                            "a-button",
                            { on: { click: _vm.calcelWorkEdit } },
                            [_vm._v(" 取消 ")]
                          ),
                      !_vm.workEditable
                        ? _c(
                            "a-button",
                            {
                              key: "submit",
                              attrs: { type: "primary" },
                              on: { click: _vm.editWork },
                            },
                            [_vm._v(" 编辑 ")]
                          )
                        : _c(
                            "a-button",
                            {
                              key: "submit",
                              attrs: { type: "primary" },
                              on: { click: _vm.saveWork },
                            },
                            [_vm._v(" 保存 ")]
                          ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.workVisible,
                callback: function ($$v) {
                  _vm.workVisible = $$v
                },
                expression: "workVisible",
              },
            },
            [
              _c(
                "a-form-model",
                _vm._b(
                  { ref: "workForm", attrs: { model: _vm.formData.userFile } },
                  "a-form-model",
                  _vm.workLayout,
                  false
                ),
                [
                  _vm.workEditable
                    ? _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            prop: "workDays",
                            label: "通常每周可工作日",
                          },
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                mode: "multiple",
                                placeholder: "通常每周可工作日",
                              },
                              on: { change: _vm.workDaysChange },
                              model: {
                                value: _vm.workDays,
                                callback: function ($$v) {
                                  _vm.workDays = $$v
                                },
                                expression: "workDays",
                              },
                            },
                            [
                              _c(
                                "a-select-option",
                                {
                                  attrs: { value: 1, disabled: _vm.selectDis },
                                },
                                [_vm._v("周一")]
                              ),
                              _c(
                                "a-select-option",
                                {
                                  attrs: { value: 2, disabled: _vm.selectDis },
                                },
                                [_vm._v("周二")]
                              ),
                              _c(
                                "a-select-option",
                                {
                                  attrs: { value: 3, disabled: _vm.selectDis },
                                },
                                [_vm._v("周三")]
                              ),
                              _c(
                                "a-select-option",
                                {
                                  attrs: { value: 4, disabled: _vm.selectDis },
                                },
                                [_vm._v("周四")]
                              ),
                              _c(
                                "a-select-option",
                                {
                                  attrs: { value: 5, disabled: _vm.selectDis },
                                },
                                [_vm._v("周五")]
                              ),
                              _c(
                                "a-select-option",
                                {
                                  attrs: { value: 6, disabled: _vm.selectDis },
                                },
                                [_vm._v("周六")]
                              ),
                              _c(
                                "a-select-option",
                                {
                                  attrs: { value: 7, disabled: _vm.selectDis },
                                },
                                [_vm._v("周日")]
                              ),
                              _c("a-select-option", { attrs: { value: -1 } }, [
                                _vm._v("跳过中国法定节假日"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "info-item",
                        {
                          attrs: {
                            "label-width": "220",
                            label: "通常每周可工作日",
                          },
                        },
                        _vm._l(
                          Array.from(_vm.workDays || []).sort((a, b) => {
                            return a - b
                          }),
                          function (item, index) {
                            return _c("span", { key: item }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    item == 1
                                      ? "周一"
                                      : item == 2
                                      ? "周二"
                                      : item == 3
                                      ? "周三"
                                      : item == 4
                                      ? "周四"
                                      : item == 5
                                      ? "周五"
                                      : item == 6
                                      ? "周六"
                                      : item == 7
                                      ? "周日"
                                      : item == -1
                                      ? "跳过中国法定节假日"
                                      : ""
                                  ) +
                                  _vm._s(
                                    index == _vm.workDays.length - 1 ? "" : "，"
                                  ) +
                                  "\n          "
                              ),
                            ])
                          }
                        ),
                        0
                      ),
                  (_vm.labelList.filter(
                    (item) =>
                      item.depart.departName.indexOf("日语部") > -1 ||
                      item.depart.departName.indexOf("韩语部") > -1
                  ).length &&
                    !_vm.isAdmin) ||
                  (_vm.labelList.filter(
                    (item) =>
                      item.depart.departName.indexOf("日语部") > -1 ||
                      item.depart.departName.indexOf("韩语部") > -1
                  ).length &&
                    _vm.isTongchou)
                    ? _c("div", { staticStyle: { "margin-bottom": "24px" } }, [
                        _vm._v(
                          "\n          工作日单一职位最大任务数量：\n        "
                        ),
                      ])
                    : _vm._e(),
                  (_vm.labelList.filter(
                    (item) =>
                      item.depart.departName.indexOf("日语部") > -1 ||
                      item.depart.departName.indexOf("韩语部") > -1
                  ).length &&
                    !_vm.isAdmin) ||
                  (_vm.labelList.filter(
                    (item) =>
                      item.depart.departName.indexOf("日语部") > -1 ||
                      item.depart.departName.indexOf("韩语部") > -1
                  ).length &&
                    _vm.isTongchou)
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            color: "red",
                            "font-size": "12px",
                            position: "relative",
                            top: "-11px",
                          },
                        },
                        [
                          _vm._v(
                            "\n          注意：请在输入框内填写当天只做单一阶段的最大任务数量。\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  (_vm.labelList.filter(
                    (item) =>
                      item.depart.departName.indexOf("日语部") > -1 ||
                      item.depart.departName.indexOf("韩语部") > -1
                  ).length &&
                    !_vm.isAdmin) ||
                  (_vm.labelList.filter(
                    (item) =>
                      item.depart.departName.indexOf("日语部") > -1 ||
                      item.depart.departName.indexOf("韩语部") > -1
                  ).length &&
                    _vm.isTongchou)
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            color: "red",
                            "font-size": "12px",
                            position: "relative",
                            top: "-11px",
                          },
                        },
                        [
                          _vm._v(
                            "\n          例，一天只做翻译，能做10话则填入10\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  (_vm.labelList.filter(
                    (item) =>
                      item.depart.departName.indexOf("日语部") > -1 ||
                      item.depart.departName.indexOf("韩语部") > -1
                  ).length &&
                    !_vm.isAdmin) ||
                  (_vm.labelList.filter(
                    (item) =>
                      item.depart.departName.indexOf("日语部") > -1 ||
                      item.depart.departName.indexOf("韩语部") > -1
                  ).length &&
                    _vm.isTongchou)
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-bottom": "24px",
                            "margin-top": "10px",
                          },
                        },
                        [
                          _c(
                            "a-radio-group",
                            {
                              model: {
                                value: _vm.scheduleIsDaily,
                                callback: function ($$v) {
                                  _vm.scheduleIsDaily = $$v
                                },
                                expression: "scheduleIsDaily",
                              },
                            },
                            [
                              _c(
                                "a-radio",
                                {
                                  attrs: {
                                    disabled: !_vm.workEditable,
                                    value: 1,
                                  },
                                },
                                [_vm._v("每日数值相同")]
                              ),
                              _c(
                                "a-radio",
                                {
                                  attrs: {
                                    value: 0,
                                    disabled:
                                      Array.from(_vm.workDays || []).some(
                                        (item) => item == -1
                                      ) || !_vm.workEditable,
                                  },
                                },
                                [_vm._v("每日数值不同")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  (!_vm.isAdmin || _vm.isTongchou) &&
                  _vm.labelList.filter(
                    (item) =>
                      item.week == "WORK_DAY" &&
                      (item.depart.departName.indexOf("日语部") > -1 ||
                        item.depart.departName.indexOf("韩语部") > -1)
                  ).length &&
                  _vm.scheduleIsDaily
                    ? [
                        _vm.workEditable
                          ? _vm._l(
                              _vm.labelList.filter(
                                (item) =>
                                  item.week == "WORK_DAY" &&
                                  (item.depart.departName.indexOf("日语部") >
                                    -1 ||
                                    item.depart.departName.indexOf("韩语部") >
                                      -1)
                              ),
                              function (item) {
                                return _c(
                                  "a-form-model-item",
                                  {
                                    key: item.departId + "-" + item.positionId,
                                    attrs: {
                                      prop: "workCount",
                                      label:
                                        item.position &&
                                        item.position.positionName
                                          ? (item.depart &&
                                              item.depart.departName) +
                                            "-" +
                                            item.position.positionName
                                          : "",
                                    },
                                  },
                                  [
                                    _c("a-input-number", {
                                      attrs: {
                                        min: 0,
                                        placeholder: "请输入可工作数量",
                                      },
                                      on: { blur: _vm.workBlur },
                                      model: {
                                        value: item.count,
                                        callback: function ($$v) {
                                          _vm.$set(item, "count", $$v)
                                        },
                                        expression: "item.count",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._l(
                              _vm.labelList.filter(
                                (item) =>
                                  item.week == "WORK_DAY" &&
                                  (item.depart.departName.indexOf("日语部") >
                                    -1 ||
                                    item.depart.departName.indexOf("韩语部") >
                                      -1)
                              ),
                              function (item) {
                                return _c(
                                  "info-item",
                                  {
                                    key: item.departId + "-" + item.positionId,
                                    attrs: {
                                      "label-width": "220",
                                      prop: "workCount",
                                      label:
                                        item.position &&
                                        item.position.positionName
                                          ? (item.depart &&
                                              item.depart.departName) +
                                            "-" +
                                            item.position.positionName
                                          : "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.count) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              }
                            ),
                      ]
                    : (!_vm.isAdmin || _vm.isTongchou) &&
                      _vm.labelList.filter(
                        (item) =>
                          item.week != "WORK_DAY" &&
                          (item.depart.departName.indexOf("日语部") > -1 ||
                            item.depart.departName.indexOf("韩语部") > -1)
                      ).length &&
                      !_vm.scheduleIsDaily
                    ? [
                        _c("a-table", {
                          attrs: {
                            columns: _vm.columns.filter((item, index) =>
                              _vm.workDays.some((i) => i == index || !index)
                            ),
                            scroll: { x: "100%", y: "350px" },
                            pagination: false,
                            "data-source": _vm.weekTableData.filter(
                              (data) =>
                                data.departAndPosition.indexOf("日语部") > -1 ||
                                data.departAndPosition.indexOf("韩语部") > -1
                            ),
                            bordered: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              _vm._l(
                                [
                                  "MONDAY",
                                  "TUESDAY",
                                  "WEDNESDAY",
                                  "THURSDAY",
                                  "FRIDAY",
                                  "SATURDAY",
                                  "SUNDAY",
                                ],
                                function (col, idx) {
                                  return {
                                    key: col,
                                    fn: function (text, record, index) {
                                      return [
                                        _c(
                                          "div",
                                          { key: idx },
                                          [
                                            record.editable
                                              ? _c("a-input-number", {
                                                  staticStyle: {
                                                    margin: "-5px 0",
                                                  },
                                                  attrs: {
                                                    min: 0,
                                                    value: record[col],
                                                  },
                                                  on: {
                                                    change: (e) =>
                                                      _vm.handleChange(
                                                        e,
                                                        record.departAndPosition,
                                                        col
                                                      ),
                                                  },
                                                })
                                              : [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(record[col]) +
                                                      "\n                "
                                                  ),
                                                ],
                                          ],
                                          2
                                        ),
                                      ]
                                    },
                                  }
                                }
                              ),
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : _vm._e(),
                  _vm.workEditable
                    ? _c(
                        "a-form-model-item",
                        { attrs: { prop: "applyReason", label: "修改理由" } },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入修改理由" },
                            model: {
                              value: _vm.formData.applyReason,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "applyReason", $$v)
                              },
                              expression: "formData.applyReason",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
              _c("div"),
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: {
                keyboard: false,
                "mask-closable": false,
                closable: false,
                title: "申请工作",
                width: 420,
              },
              scopedSlots: _vm._u([
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c("a-button", { on: { click: _vm.workModalCancel } }, [
                        _vm._v(" 取消 "),
                      ]),
                      _c(
                        "a-button",
                        {
                          key: "submit",
                          attrs: { type: "primary" },
                          on: { click: _vm.workModalOk },
                        },
                        [_vm._v(" 提交 ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.workModalVisible,
                callback: function ($$v) {
                  _vm.workModalVisible = $$v
                },
                expression: "workModalVisible",
              },
            },
            [
              _vm.workDates.length > 1
                ? _c("div", [
                    _vm._v(
                      "\n        请输入（" +
                        _vm._s(_vm.workDates[0] && _vm.workDates[0].date) +
                        "~" +
                        _vm._s(
                          _vm.workDates[_vm.workDates.length - 1] &&
                            _vm.workDates[_vm.workDates.length - 1].date
                        ) +
                        "）可安排数量。\n      "
                    ),
                  ])
                : _vm._e(),
              _vm.workDates.length == 1
                ? _c("div", [
                    _vm._v(
                      "请输入（" +
                        _vm._s(_vm.workDates[0] && _vm.workDates[0].date) +
                        "）可安排数量。"
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "12px",
                    color: "red",
                    "margin-top": "5px",
                    "margin-bottom": "18px",
                  },
                },
                [_vm._v("注意：未填入数值无法安排任务")]
              ),
              _c(
                "div",
                {
                  staticStyle: { "max-height": "500px", "overflow-y": "auto" },
                },
                _vm._l(
                  _vm.labelList.filter(
                    (item) =>
                      item.week == "WORK_DAY" &&
                      ((item) =>
                        item.depart.departName.indexOf("日语部") > -1 ||
                        item.depart.departName.indexOf("韩语部") > -1)
                  ),
                  function (item) {
                    return _c(
                      "div",
                      { key: item.departId + "-" + item.positionId },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              "margin-bottom": "18px",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  item.position && item.position.positionName
                                    ? (item.depart && item.depart.departName) +
                                        "-" +
                                        item.position.positionName
                                    : ""
                                ) +
                                "：\n          "
                            ),
                          ]
                        ),
                        _vm._l(
                          _vm.workDates.filter(
                            (workDate) =>
                              workDate.dp ==
                              item.departId + "-" + item.positionId
                          ),
                          function (workDate) {
                            return _c(
                              "div",
                              {
                                key: workDate.date + item.positionId,
                                staticStyle: {
                                  display: "flex",
                                  "margin-bottom": "12px",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c("div", { staticStyle: { width: "100px" } }, [
                                  _vm._v(_vm._s(workDate.date) + "："),
                                ]),
                                _c(
                                  "div",
                                  [
                                    _c("a-input-number", {
                                      attrs: {
                                        min: 0,
                                        placeholder: "请输入可工作数量/天",
                                      },
                                      model: {
                                        value: workDate.value,
                                        callback: function ($$v) {
                                          _vm.$set(workDate, "value", $$v)
                                        },
                                        expression: "workDate.value",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    )
                  }
                ),
                0
              ),
            ]
          ),
          _c("term-list-modal", { ref: "termListModal" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }