<template>
  <a-spin :spinning="loading" class="my-task" :style="backCss">
    <div class="confirm-list-content">
      <div class="confirm-list-top">
        <div>
          <a-radio-group v-model="radioValue">
            <a-radio :value="1" v-if="positions.some(p => p.name == '校对' || p.name == '翻译')">
              确认列表
            </a-radio>
            <a-radio :value="2" v-if="positions.some(p => p.name == '校对' || p.name == '终审')">
              其他职位待确认
            </a-radio>
          </a-radio-group>
        </div>
        <div>
          <a-button
            @click="tBatchReject"
            :disabled="!tSelectionRows.length || tSelectionRows.some(item => item.status == '未确认')"
            v-if="positions.some(p => p.name == '翻译') && positions.every(p => p.name != '终审')"
            class="mr-3"
          >
            取消确认
          </a-button>
          <a-button
            @click="pBatchReject"
            :disabled="!pSelectionRows.length || pSelectionRows.some(item => item.status == '未确认')"
            v-else
            class="mr-3"
          >
            取消确认
          </a-button>
          <a-button
            @click="tBatchConfirm"
            :disabled="!tSelectionRows.length || tSelectionRows.some(item => item.status == '已确认')"
            v-if="positions.some(p => p.name == '翻译') && positions.every(p => p.name != '终审')"
            type="primary"
          >
            确认
          </a-button>
          <a-button
            @click="pBatchConfirm"
            :disabled="!pSelectionRows.length || pSelectionRows.some(item => item.status == '已确认')"
            v-else
            type="primary"
          >
            确认
          </a-button>
        </div>
      </div>
      <div class="confirm-list-bottom" v-if="!loading">
        <a-table
          v-show="positions.some(p => p.name == '翻译') && positions.every(p => p.name != '终审') && radioValue == 1"
          :rowKey="record => record.id"
          :scroll="{ x: 'max-content', y: 'calc(100vh - 400px)',scrollToFirstRowOnChange:true }"
          :columns="translateColumns"
          @change="tHandleChange"
          :rowSelection="{ selectedRowKeys: tSelectedRowKeys, onChange: ontSelectChange, columnWidth: 45 }"
          :pagination="{ ...translateIpagination, total: translatorConfirmList.length }"
          :data-source="translatorConfirmList"
        >
          <span
            slot="filterIcon"
            slot-scope="filtered"
            :style="{ color: filtered ? '#108ee9' : undefined, fontSize: '16px' }"
            class="iconfont icon-shaixuan icon"
          ></span>
          <span @click.stop slot="productionName" slot-scope="text, record">
            {{ record.productionSequenceNum + '-' + record.productionName + '-' + record.chapterSequenceNum }}
          </span>
          <span @click.stop slot="status" slot-scope="text, record">
            <span style="padding-left:8px;">{{ record.status }}</span>
          </span>
          <span @click.stop slot="taskStatus" slot-scope="text, record">
            <span style="padding-left:8px;">{{ record.taskStatus }}</span>
          </span>
          <span @click.stop slot="action" slot-scope="text, record">
            <a @click="goToTask(record)">查看详情</a>
            <a style="margin-left:15px" v-if="record.status == '未确认'" @click="handleConfirm(record)">确认</a>
            <a style="margin-left:15px" v-else @click="handleReject(record)">取消确认</a>
          </span>
        </a-table>
        <a-table
          v-show="positions.some(p => p.name == '翻译') && positions.some(p => p.name == '终审') && radioValue == 1"
          :rowKey="record => record.id"
          :scroll="{ x: 'max-content', y: 'calc(100vh - 400px)',scrollToFirstRowOnChange:true }"
          :columns="translateColumns"
          @change="p1HandleChange"
          :rowSelection="{ selectedRowKeys: pSelectedRowKeys, onChange: onpSelectChange, columnWidth: 45 }"
          :pagination="{ ...p1Ipagination, total: proofreaderConfirmList.length }"
          :data-source="proofreaderConfirmList"
        >
          <span
            slot="filterIcon"
            slot-scope="filtered"
            :style="{ color: filtered ? '#108ee9' : undefined, fontSize: '16px' }"
            class="iconfont icon-shaixuan icon"
          ></span>
          <span @click.stop slot="productionName" slot-scope="text, record">
            {{ record.productionSequenceNum + '-' + record.productionName + '-' + record.chapterSequenceNum }}
          </span>
          <span @click.stop slot="status" slot-scope="text, record">
            <span style="padding-left:8px;">{{ record.status }}</span>
          </span>
          <span @click.stop slot="taskStatus" slot-scope="text, record">
            <span style="padding-left:8px;">{{ record.taskStatus }}</span>
          </span>
          <span @click.stop slot="action" slot-scope="text, record">
            <a @click="goToTask(record)">查看详情</a>
            <a style="margin-left:15px" v-if="record.status == '未确认'" @click="handleConfirm(record)">确认</a>
            <a style="margin-left:15px" v-else @click="handleReject(record)">取消确认</a>
          </span>
        </a-table>
        <a-table
          v-show="positions.some(p => p.name == '校对') && positions.every(p => p.name != '翻译') && radioValue == 1"
          :rowKey="record => record.sort"
          :scroll="{ x: 'max-content', y: 'calc(100vh - 400px)',scrollToFirstRowOnChange:true }"
          :columns="p1Columns"
          @change="p1HandleChange"
          :rowSelection="{ selectedRowKeys: pSelectedRowKeys, onChange: onpSelectChange, columnWidth: 45 }"
          :pagination="{ ...p1Ipagination, total: proofreaderConfirmList.length }"
          :data-source="proofreaderConfirmList"
        >
          <span
            slot="filterIcon"
            slot-scope="filtered"
            :style="{ color: filtered ? '#108ee9' : undefined, fontSize: '16px' }"
            class="iconfont icon-shaixuan icon"
          ></span>
          <span @click.stop slot="productionName" slot-scope="text, record">
            {{ record.productionSequenceNum + '-' + record.productionName + '-' + record.chapterSequenceNum }}
          </span>
          <span @click.stop slot="status" slot-scope="text, record">
            <span style="padding-left:8px;">{{ record.status }}</span>
          </span>
          <span @click.stop slot="taskStatus" slot-scope="text, record">
            <span style="padding-left:8px;">{{ record.taskStatus }}</span>
          </span>
          <span @click.stop slot="action" slot-scope="text, record">
            <a @click="goToTask(record)">查看详情</a>
            <a style="margin-left:15px" v-if="record.status == '未确认'" @click="handleConfirm(record)">确认</a>
            <a style="margin-left:15px" v-else @click="handleReject(record)">取消确认</a>
          </span>
        </a-table>
        <a-table
          v-if="positions.some(p => p.name == '校对') && radioValue == 2"
          :rowKey="record => record.id"
          :scroll="{ x: 'max-content', y: 'calc(100vh - 400px)',scrollToFirstRowOnChange:true }"
          :columns="p2Columns"
          @change="p2HandleChange"
          :pagination="{ ...p2Ipagination, total: proofreaderToBeConfirmList.length }"
          :data-source="proofreaderToBeConfirmList"
        >
          <span
            slot="filterIcon"
            slot-scope="filtered"
            :style="{ color: filtered ? '#108ee9' : undefined, fontSize: '16px' }"
            class="iconfont icon-shaixuan icon"
          ></span>
          <span @click.stop slot="productionName" slot-scope="text, record">
            {{ record.productionSequenceNum + '-' + record.productionName + '-' + record.chapterSequenceNum }}
          </span>
          <span @click.stop slot="status" slot-scope="text, record">
            <span style="padding-left:8px;">{{ record.status }}</span>
          </span>
          <span @click.stop slot="taskStatus" slot-scope="text, record">
            <span style="padding-left:8px;">{{ record.taskStatus }}</span>
          </span>
        </a-table>
        <a-table
          v-else-if="positions.some(p => p.name == '终审') && positions.some(p => p.name == '翻译') && radioValue == 2"
          :rowKey="record => record.id"
          :scroll="{ x: 'max-content', y: 'calc(100vh - 400px)',scrollToFirstRowOnChange:true }"
          :columns="fColumns"
          @change="fHandleChange"
          :pagination="{ ...p2Ipagination, total: proofreaderToBeConfirmList.length }"
          :data-source="proofreaderToBeConfirmList"
        >
          <span
            slot="filterIcon"
            slot-scope="filtered"
            :style="{ color: filtered ? '#108ee9' : undefined, fontSize: '16px' }"
            class="iconfont icon-shaixuan icon"
          ></span>
          <span @click.stop slot="productionName" slot-scope="text, record">
            {{ record.productionSequenceNum + '-' + record.productionName + '-' + record.chapterSequenceNum }}
          </span>
          <span @click.stop slot="taskStatus" slot-scope="text, record">
            <span style="padding-left:8px;">{{ record.taskStatus }}</span>
          </span>
          <span @click.stop slot="status" slot-scope="text, record">
            <span style="padding-left:8px;">{{ record.status }}</span>
          </span>
        </a-table>
        <a-table
          v-else-if="
            positions.some(p => p.name == '终审') &&
              positions.every(p => p.name != '翻译' && p.name != '校对') &&
              radioValue == 2
          "
          :rowKey="record => record.sort"
          :scroll="{ x: 'max-content', y: 'calc(100vh - 400px)',scrollToFirstRowOnChange:true }"
          :columns="fColumns"
          @change="fHandleChange"
          :pagination="{ ...fIpagination, total: finalCheckTobeConfirmList.length }"
          :data-source="finalCheckTobeConfirmList"
        >
          <span
            slot="filterIcon"
            slot-scope="filtered"
            :style="{ color: filtered ? '#108ee9' : undefined, fontSize: '16px' }"
            class="iconfont icon-shaixuan icon"
          ></span>
          <span @click.stop slot="productionName" slot-scope="text, record">
            {{ record.productionSequenceNum + '-' + record.productionName + '-' + record.chapterSequenceNum }}
          </span>
          <span @click.stop slot="status" slot-scope="text, record">
            <span style="padding-left:8px;">{{ record.status }}</span>
          </span>
        </a-table>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
export default {
  props: {
    userInfo: {
      type: Object,
      default: () => ({})
    },
    confirmTaskName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      radioValue: 1,
      tSelectedRowKeys: [],
      pSelectedRowKeys: [],
      translatorConfirmList: [],
      tList: [],
      proofreaderConfirmList: [],
      p1List: [],
      proofreaderToBeConfirmList: [],
      p2List: [],
      finalCheckTobeConfirmList: [],
      fList: [],
      loading: false,
      positions: [],
      translateIpagination: {
        current: 1,
        pageSize: 50,
        defaultPageSize: 50,
        pageSizeOptions: ['10', '15', '20', '30', '40', '50'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: false,
        total: 0
      },
      p1Ipagination: {
        current: 1,
        pageSize: 50,
        defaultPageSize: 50,
        pageSizeOptions: ['10', '15', '20', '30', '40', '50'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: false,
        total: 0
      },
      p2Ipagination: {
        current: 1,
        pageSize: 50,
        defaultPageSize: 50,
        pageSizeOptions: ['10', '15', '20', '30', '40', '50'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: false,
        total: 0
      },
      fIpagination: {
        current: 1,
        pageSize: 50,
        defaultPageSize: 50,
        pageSizeOptions: ['10', '15', '20', '30', '40', '50'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: false,
        total: 0
      },
      translateColumns: [
        {
          title: '序号',
          dataIndex: 'sort',
          scopedSlots: { customRender: 'sort' },
          width: 50
        },
        {
          title: '任务名称',
          dataIndex: 'productionName',
          scopedSlots: { customRender: 'productionName' },
          width: 150
        },
        {
          title: '当前章节进度',
          dataIndex: 'taskStatus',
          scopedSlots: { customRender: 'taskStatus' },
          filters: [
            { text: '校对已完成', value: '校对已完成' },
            { text: '终审已完成', value: '终审已完成' }
          ],
          scopedSlots: { customRender: 'taskStatus', filterIcon: 'filterIcon' },
          width: 150
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          filters: [
            { text: '已确认', value: '已确认' },
            { text: '未确认', value: '未确认' }
          ],
          scopedSlots: { customRender: 'status', filterIcon: 'filterIcon' },
          width: 120
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          width: 120
        }
      ],
      p1Columns: [
        {
          title: '序号',
          dataIndex: 'sort',
          scopedSlots: { customRender: 'sort' },
          width: 50
        },
        {
          title: '任务名称',
          dataIndex: 'productionName',
          scopedSlots: { customRender: 'productionName' },
          width: 150
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          filters: [
            { text: '已确认', value: '已确认' },
            { text: '未确认', value: '未确认' }
          ],
          scopedSlots: { customRender: 'status', filterIcon: 'filterIcon' },
          width: 120
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          width: 120
        }
      ],
      p2Columns: [
        {
          title: '序号',
          dataIndex: 'sort',
          scopedSlots: { customRender: 'sort' },
          width: 50
        },
        {
          title: '任务名称',
          dataIndex: 'productionName',
          scopedSlots: { customRender: 'productionName' },
          width: 150
        },
        {
          title: '当前任务确认状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          filters: [
            { text: '翻译未确认', value: '翻译未确认' },
            { text: '校对未确认', value: '校对未确认' }
          ],
          scopedSlots: { customRender: 'status', filterIcon: 'filterIcon' },
          width: 150
        }
      ],
      fColumns: [
        {
          title: '序号',
          dataIndex: 'sort',
          scopedSlots: { customRender: 'sort' },
          width: 50
        },
        {
          title: '任务名称',
          dataIndex: 'productionName',
          scopedSlots: { customRender: 'productionName' },
          width: 150
        },
        {
          title: '当前任务确认状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          filters: [
            { text: '翻译未确认', value: '翻译未确认' },
            { text: '校对未确认', value: '校对未确认' }
          ],
          scopedSlots: { customRender: 'status', filterIcon: 'filterIcon' },
          width: 150
        }
      ],
      pSelectionRows: [],
      tSelectionRows: []
    }
  },
  computed: {
    backCss() {
      let color = this.$store.state.app.color
    }
  },
  methods: {
    goToTask(record) {
      this.$router.push({
        path: `/translate/mytasks/detail/${record.taskId}/${record.productionId}/${
          record.chapterId
        }?record=${encodeURIComponent(
          JSON.stringify({
            platformId: record.platformId
          })
        )}&isMyTask=${false}&name=${record.productionName + '-' + record.chapterSequenceNum + '-' + record.stageName}`
      })
    },
    async handleConfirm(row) {
      this.loading = true
      const res = await postAction(`/task/confirm`, [{ chapterId: row.chapterId, confirm: true }])
      if (res.code == 200) {
        this.$message.success('已确认')
        this.loading = false
        this.getTableData()
      } else {
        this.$message.error(res.msg)
      }
    },
    async pBatchConfirm() {
      this.loading = true
      let data = this.pSelectionRows.map(item => {
        return { chapterId: item.chapterId, confirm: true }
      })
      const res = await postAction(`/task/confirm`, data)
      if (res.code == 200) {
        this.loading = false
        this.$message.success('已确认')
        this.getTableData()
      } else {
        this.$message.error(res.msg)
      }
    },
    async tBatchConfirm() {
      this.loading = true
      let data = this.tSelectionRows.map(item => {
        return { chapterId: item.chapterId, confirm: true }
      })
      const res = await postAction(`/task/confirm`, data)
      if (res.code == 200) {
        this.loading = false
        this.$message.success('已确认')
        this.getTableData()
      } else {
        this.$message.error(res.msg)
      }
    },
    async pBatchReject() {
      this.loading = true
      let data = this.pSelectionRows.map(item => {
        return { chapterId: item.chapterId, confirm: false }
      })
      const res = await postAction(`/task/confirm`, data)
      if (res.code == 200) {
        this.loading = false
        this.$message.success('已取消')
        this.getTableData()
      } else {
        this.$message.error(res.msg)
      }
    },
    async tBatchReject() {
      this.loading = true
      let data = this.tSelectionRows.map(item => {
        return { chapterId: item.chapterId, confirm: false }
      })
      this.loading = true
      const res = await postAction(`/task/confirm`, data)
      if (res.code == 200) {
        this.loading = false
        this.$message.success('已取消')
        this.getTableData()
      } else {
        this.$message.error(res.msg)
      }
    },
    async handleReject(row) {
      this.loading = true
      const res = await postAction(`/task/confirm`, [{ chapterId: row.chapterId, confirm: false }])
      if (res.code == 200) {
        this.loading = false
        this.$message.success('已取消')
        this.getTableData()
      } else {
        this.$message.error(res.msg)
      }
    },
    onpSelectChange(selectedRowKeys, selectionRows) {
      this.pSelectedRowKeys = selectedRowKeys
      this.pSelectionRows = selectionRows
    },
    ontSelectChange(selectedRowKeys, selectionRows) {
      this.tSelectedRowKeys = selectedRowKeys
      this.tSelectionRows = selectionRows
    },
    tHandleChange(pagination, filters, sorter) {
      this.translateIpagination.current = pagination.current
      if (JSON.stringify(filters) == '{}') {
      } else {
        this.translatorConfirmList = []
        this.tList.forEach(item => {
          for (let key in filters) {
            if (filters[key] && filters[key].some(value => value == item[key])) {
              this.translatorConfirmList.push(item)
            } else if (filters[key] && !filters[key].length) {
              this.translatorConfirmList.push(item)
            }
          }
        })
      }
    },
    p1HandleChange(pagination, filters, sorter) {
      this.p1Ipagination.current = pagination.current
      if (JSON.stringify(filters) == '{}') {
      } else {
        this.proofreaderConfirmList = []
        this.p1List.forEach(item => {
          for (let key in filters) {
            if (filters[key] && filters[key].some(value => value == item[key])) {
              this.proofreaderConfirmList.push(item)
            } else if (filters[key] && !filters[key].length) {
              this.proofreaderConfirmList.push(item)
            }
          }
        })
      }
    },
    p2HandleChange(pagination, filters, sorter) {
      this.p2Ipagination.current = pagination.current
      if (JSON.stringify(filters) == '{}') {
      } else {
        this.proofreaderToBeConfirmList = []
        this.p2List.forEach(item => {
          for (let key in filters) {
            if (filters[key] && filters[key].some(value => value == item[key])) {
              this.proofreaderToBeConfirmList.push(item)
            } else if (filters[key] && !filters[key].length) {
              this.proofreaderToBeConfirmList.push(item)
            }
          }
        })
      }
    },
    fHandleChange(pagination, filters, sorter) {
      this.fIpagination.current = pagination.current
      if (JSON.stringify(filters) == '{}') {
      } else {
        this.finalCheckTobeConfirmList = []
        this.fList.forEach(item => {
          for (let key in filters) {
            if (filters[key] && filters[key].some(value => value == item[key])) {
              this.finalCheckTobeConfirmList.push(item)
            } else if (filters[key] && !filters[key].length) {
              this.finalCheckTobeConfirmList.push(item)
            }
          }
        })
      }
    },
    async getTableData() {
      this.loading = true
      const res = await postAction('/task/confirm/list')
      this.loading = false
      this.positions = this.userInfo.positions || []
      if (this.positions.length && this.positions.every(p => p.name != '翻译' && p.name != '校对')) {
        this.radioValue = 2
      }
      if (res.code == 200) {
        res.data.translatorConfirmList?.forEach((item, index) => {
          item.sort = index + 1
        })
        res.data.proofreaderConfirmList?.forEach((item, index) => {
          item.sort = index + 1
        })
        res.data.proofreaderToBeConfirmList?.forEach((item, index) => {
          item.sort = index + 1
        })
        res.data.finalCheckTobeConfirmList?.forEach((item, index) => {
          item.sort = index + 1
        })
        this.translatorConfirmList = res.data.translatorConfirmList || []
        this.tList = JSON.parse(JSON.stringify(this.translatorConfirmList))
        this.proofreaderConfirmList = res.data.proofreaderConfirmList || []
        this.p1List = JSON.parse(JSON.stringify(this.proofreaderConfirmList))
        this.proofreaderToBeConfirmList = res.data.proofreaderToBeConfirmList || []
        this.p2List = JSON.parse(JSON.stringify(this.proofreaderToBeConfirmList))
        this.finalCheckTobeConfirmList = res.data.finalCheckTobeConfirmList || []
        this.fList = JSON.parse(JSON.stringify(this.finalCheckTobeConfirmList))
        this.pSelectedRowKeys = []
        this.pSelectionRows = []
        this.tSelectedRowKeys = []
        this.tSelectionRows = []
        this.$emit('changeConfirmTableTotal', res.data.total || 0)
        this.search()
      }
    },
    search() {
      if (this.tList.length) {
        this.translatorConfirmList = this.tList.filter(
          item =>
            item.productionName.indexOf(this.confirmTaskName) > -1 ||
            item.productionSequenceNum.indexOf(this.confirmTaskName) > -1 ||
            item.chapterSequenceNum.indexOf(this.confirmTaskName) > -1
        )
      }
      if (this.p1List.length) {
        this.proofreaderConfirmList = this.p1List.filter(
          item =>
            item.productionName.indexOf(this.confirmTaskName) > -1 ||
            item.productionSequenceNum.indexOf(this.confirmTaskName) > -1 ||
            item.chapterSequenceNum.indexOf(this.confirmTaskName) > -1
        )
      }
      if (this.p2List.length) {
        this.proofreaderToBeConfirmList = this.p2List.filter(
          item =>
            item.productionName.indexOf(this.confirmTaskName) > -1 ||
            item.productionSequenceNum.indexOf(this.confirmTaskName) > -1 ||
            item.chapterSequenceNum.indexOf(this.confirmTaskName) > -1
        )
      }
      if (this.fList.length) {
        this.finalCheckTobeConfirmList = this.fList.filter(
          item =>
            item.productionName.indexOf(this.confirmTaskName) > -1 ||
            item.productionSequenceNum.indexOf(this.confirmTaskName) > -1 ||
            item.chapterSequenceNum.indexOf(this.confirmTaskName) > -1
        )
      }
    }
  },
  created() {
    this.$bus.$on('reload', keys => {
      this.$nextTick(() => {
        this.getTableData()
      })
    })
  }
}
</script>

<style lang="less" scoped>
.my-task {
  min-height: 200px;
}
.icon-shaixuan:before {
  position: relative;
  top: 12px;
}
.confirm-list-content {
  .confirm-list-top {
    height: 30px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
  }
}
/deep/ .ant-table-placeholder {
  border: none !important;
}
&::-webkit-scrollbar-button {
  height: 0;
}
&::-webkit-scrollbar {
  border-radius: 0;
  position: relative;
  width: 5px;
}
&::-webkit-scrollbar-track {
  border-radius: 0;
  background: #fff;
}
&::-webkit-scrollbar-track-piece {
  border-radius: 0;
}
&::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
}
</style>
